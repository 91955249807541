import React, { Component, memo } from "react";
import ReactGA from "react-ga";
import { Carousel } from "antd";

import bannerItau from "../assets/banner-itau.png";

import "../css/app.css";

const SponsorItau = memo(() => {
  return (
    <a
      onClick={() => {
        ReactGA.event({
          category: "Action",
          action: "Click",
          label: "Click no banner do Itaú",
        });
      }}
      href="https://www.issomudaogame.gg/?utm_source=gmcl&utm_medium=cpm&utm_campaign=gl-in-ndndnd-nd-bran-gamersclub&utm_content=af002-nd-gmcl-ca007-sec-cpm-cros-audi-audience-970x250-bill-gl00001509"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={bannerItau} alt="Itaú" />
    </a>
  );
});

class SponsorsArea extends Component {
  render() {
    return (
      <div className="advertising">
        <div className="banner">
          {this.props.game === "CS" && (
            <Carousel autoplay={true} autoplaySpeed={3000}>
              <SponsorItau />
            </Carousel>
          )}
          {this.props.game === "Valorant" && (
            <Carousel autoplay={true} autoplaySpeed={3000}>
              <SponsorItau />
            </Carousel>
          )}
        </div>
      </div>
    );
  }
}

export default SponsorsArea;
