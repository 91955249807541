import CsGo from "../pages/CsGo";
import Valorant from "../pages/Valorant";

const publicRoutes = [
  { path: "/csgo", exact: true, component: CsGo },
  { path: "/valorant", exact: true, component: Valorant },
  { path: "/", exact: true, component: CsGo },
];

export { publicRoutes };
