import React, { useMemo } from "react";
import { GC_AWARDS_LOGIN } from "../constants/api";
import { GAME_CSS, GAME_TAG } from "../constants/game";
import { ALinkButton } from "./theme/buttons/ALinkButton";

export const LoginMGBtn = ({ game }) => {
  const prefixCss = useMemo(
    () =>
      game === GAME_TAG.CS
        ? GAME_CSS.CS
        : GAME_CSS.VALORANT,
    [game]
  );
  return (
    <section className={`pd-50 ${prefixCss}-voting`} id="voting">
      <div>
        <div className="section-title">
          <h1>Você não pode votar :(</h1>
          <p className={`${prefixCss}-section-text`}>
            Faça login em{" "}
            <strong>{(GC_AWARDS_LOGIN || "").replace("https://", "")}</strong>{" "}
            e, logo após, atualize esta página para poder{" "}
            <strong>votar!</strong>
          </p>
        </div>
        <div>
          <hr className={`${prefixCss}-hr`} />
        </div>
        <div className="loginBtnMg">
          <ALinkButton
            href={
              game === GAME_TAG.CS ? `${GC_AWARDS_LOGIN}/csgo` : GC_AWARDS_LOGIN
            }
            game={game}
          >
            EFETUAR LOGIN
          </ALinkButton>
        </div>
      </div>
    </section>
  );
};
