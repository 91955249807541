export const VALORANT_TOTAL_CATEGORIES = 10;

export const categories = {
  MELHOR_INICIADOR: "Melhor Iniciador",
  MELHOR_CONTROLADOR: "Melhor Controlador",
  MELHOR_DUELISTA: "Melhor Duelista",
  MELHOR_SENTINELA: "Melhor Sentinela",
  MELHOR_CAPITAO: "Melhor IGL",
  MELHOR_COACH: "Melhor Coach",
  MELHOR_ORGANIZACAO: "Melhor Organização",
  JOGADORA_DO_ANO: "Player do Ano (Cenário Inclusivo) by Itaú",
  JOGADOR_DO_ANO: "Player do Ano (Cenário Misto) by Itaú",
  PERSONALIDADE_DO_ANO: "Personalidade do Ano by Itaú",
};
