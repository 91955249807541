import React, { Component } from "react";
import Countdown from "react-countdown";
import CandidateCard from "../components/CandidateCard";
import Stepper from "../components/Stepper";
import { connect } from "react-redux";
import {
  voteForCsgoAssault,
  voteForCsgoAwp,
  voteForCsgoCaptain,
  voteForCsgoCoach,
  voteForCsgoOrg,
  voteForCsgoForeignPlayer,
  voteForCsgoMalePlayer,
  voteForCsgoFemalePlayer,
  voteForCsgoNewPlayer,
  voteForCsgoInfluencer,
  voteForCsgoLeaguePlayer,
  voteForCsgoLeagueOrg,
  voteForCsgoYoungPlayer,
  voteForValorantInitiator,
  voteForValorantController,
  voteForValorantDuelist,
  voteForValorantSentinel,
  voteForValorantCaptain,
  voteForValorantCoach,
  voteForValorantOrg,
  voteForValorantFemalePlayer,
  voteForValorantMalePlayer,
  voteForValorantInfluencer,
  choose,
} from "../actions/index";

import { categories as categoriesCSGO } from "../constants/csgo/categories";
import { categories as categoriesValorant } from "../constants/valorant/categories";
import { LoginMGBtn } from "./LoginMGBtn";
import { GAME_TAG } from "../constants/game";

import { endVotingDate } from "../constants/endVotingDate";

const mapStateToProps = (state) => {
  return {
    chosen: state.app.chosen,
    errorMessage: state.app.errorMessage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    voteForCsgoAssault: (assault) => dispatch(voteForCsgoAssault(assault)),
    voteForCsgoAwp: (awp) => dispatch(voteForCsgoAwp(awp)),
    voteForCsgoCaptain: (captain) => dispatch(voteForCsgoCaptain(captain)),
    voteForCsgoCoach: (coach) => dispatch(voteForCsgoCoach(coach)),
    voteForCsgoOrg: (org) => dispatch(voteForCsgoOrg(org)),
    voteForCsgoNewPlayer: (newPlayer) =>
      dispatch(voteForCsgoNewPlayer(newPlayer)),
    voteForCsgoForeignPlayer: (foreignPlayer) =>
      dispatch(voteForCsgoForeignPlayer(foreignPlayer)),
    voteForCsgoMalePlayer: (malePlayer) =>
      dispatch(voteForCsgoMalePlayer(malePlayer)),
    voteForCsgoFemalePlayer: (femalePlayer) =>
      dispatch(voteForCsgoFemalePlayer(femalePlayer)),
    voteForCsgoInfluencer: (influencer) =>
      dispatch(voteForCsgoInfluencer(influencer)),
    voteForCsgoLeaguePlayer: (leaguePlayer) =>
      dispatch(voteForCsgoLeaguePlayer(leaguePlayer)),
    voteForCsgoLeagueOrg: (leagueOrg) =>
      dispatch(voteForCsgoLeagueOrg(leagueOrg)),
    voteForCsgoYoungPlayer: (youngPlayer) =>
      dispatch(voteForCsgoYoungPlayer(youngPlayer)),
    voteForValorantInitiator: (initiator) =>
      dispatch(voteForValorantInitiator(initiator)),
    voteForValorantController: (controller) =>
      dispatch(voteForValorantController(controller)),
    voteForValorantDuelist: (duelist) =>
      dispatch(voteForValorantDuelist(duelist)),
    voteForValorantSentinel: (sentinel) =>
      dispatch(voteForValorantSentinel(sentinel)),
    voteForValorantCaptain: (captain) =>
      dispatch(voteForValorantCaptain(captain)),
    voteForValorantCoach: (coach) => dispatch(voteForValorantCoach(coach)),
    voteForValorantOrg: (organization) =>
      dispatch(voteForValorantOrg(organization)),
    voteForValorantFemalePlayer: (femalePlayer) =>
      dispatch(voteForValorantFemalePlayer(femalePlayer)),
    voteForValorantMalePlayer: (malePlayer) =>
      dispatch(voteForValorantMalePlayer(malePlayer)),
    voteForValorantInfluencer: (influencer) =>
      dispatch(voteForValorantInfluencer(influencer)),
    choose: (chosen) => dispatch(choose(chosen)),
  };
}

class ConnectedVoting extends Component {
  handleVote() {
    let { game } = this.props;
    let category = Object.keys(this.props.votingMoment)[0];

    if (game === "CS") {
      switch (category) {
        case categoriesCSGO.MELHOR_ASSAULT:
          this.props.voteForCsgoAssault(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.MELHOR_AWP:
          this.props.voteForCsgoAwp(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.MELHOR_CAPITAO:
          this.props.voteForCsgoCaptain(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.MELHOR_COACH:
          this.props.voteForCsgoCoach(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.MELHOR_ORGANIZACAO:
          this.props.voteForCsgoOrg(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.REVELACAO:
          this.props.voteForCsgoNewPlayer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.ESTRANGEIRO_DO_ANO:
          this.props.voteForCsgoForeignPlayer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.JOGADORA_DO_ANO:
          this.props.voteForCsgoFemalePlayer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.JOGADOR_DO_ANO:
          this.props.voteForCsgoMalePlayer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.PERSONALIDADE_DO_ANO:
          this.props.voteForCsgoInfluencer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.JOGADOR_LIGAS:
          this.props.voteForCsgoLeaguePlayer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.ORGANIZACAO_LIGAS:
          this.props.voteForCsgoLeagueOrg(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesCSGO.JOVEM_DO_ANO:
          this.props.voteForCsgoYoungPlayer(this.props.chosen);
          this.props.choose("");
          break;
        default:
          return null;
      }
    }

    if (game === "Valorant") {
      switch (category) {
        case categoriesValorant.MELHOR_INICIADOR:
          this.props.voteForValorantInitiator(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.MELHOR_CONTROLADOR:
          this.props.voteForValorantController(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.MELHOR_DUELISTA:
          this.props.voteForValorantDuelist(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.MELHOR_SENTINELA:
          this.props.voteForValorantSentinel(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.MELHOR_CAPITAO:
          this.props.voteForValorantCaptain(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.MELHOR_COACH:
          this.props.voteForValorantCoach(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.MELHOR_ORGANIZACAO:
          this.props.voteForValorantOrg(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.JOGADORA_DO_ANO:
          this.props.voteForValorantFemalePlayer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.JOGADOR_DO_ANO:
          this.props.voteForValorantMalePlayer(this.props.chosen);
          this.props.choose("");
          break;
        case categoriesValorant.PERSONALIDADE_DO_ANO:
          this.props.voteForValorantInfluencer(this.props.chosen);
          this.props.choose("");
          break;
        default:
          return null;
      }
    }
  }

  render() {
    const { game } = this.props;

    const renderCountdown = ({ completed }) => {
      let candidates = Object.values(this.props.votingMoment)[0];
      let candidatesCards = [];

      candidates.forEach((candidate, index) => {
        candidatesCards.push(
          <CandidateCard
            name={candidate.name}
            org={candidate.org}
            twitter={candidate.twitter}
            twitch={candidate.twitch}
            instagram={candidate.instagram}
            avatar={candidate.avatar}
            category={Object.keys(this.props.votingMoment)[0]}
            key={`key-${candidate.name}-${candidate.org}-${index}`}
            game={game}
          />
        );
      });

      if (game === GAME_TAG.CS) {
        if (this.props.errorMessage) {
          return <LoginMGBtn game={GAME_TAG.CS} />;
        }
        return (
          <section className="pd-50 voting-container cs-voting" id="voting">
            <div className="section-title">
              <h1>A decisão agora é sua!</h1>
            </div>
            <div>
              <hr className="cs-hr" />
            </div>
            <div className="section-description cs-section-description">
              <p>
                Clique no seu candidato favorito. Em seguida, clique no botão{" "}
                <strong>VOTAR</strong>.<br />
                Após escolher, você pode voltar para a categoria desejada
                clicando sobre ela na linha do tempo do lado direito da página.
                Você também poderá revisar seus votos e alterar suas escolhas na
                etapa de <strong>REVISÃO</strong>.
              </p>
            </div>
            <div className="container">
              <div className="voting">
                <div className="candidates">
                  <div className="award-category cs-category">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="70"
                      height="54"
                      viewBox="0 0 70 54"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.6875 11.9375L35 0.96875L42.3125 11.9375L39.3085 16.4435L46.0545 26.5626H50.758L54.008 21.6876L50.7602 16.8159L54.0124 11.9376H61.8125H66.6875H69.4716L69.1036 14.6972L64.2286 51.2598L63.9466 53.3751H61.8125H8.1875H6.05343L5.77138 51.2598L0.89638 14.6972L0.528427 11.9376H3.3125H10.625H15.9876L19.2398 16.8159L15.992 21.6876L19.242 26.5626H23.9455L30.6915 16.4435L27.6875 11.9375ZM6.09657 16.8126L10.3216 48.5001H59.6784L63.9034 16.8126H61.8125H56.617L58.9656 20.3355L59.867 21.6876L58.9656 23.0397L54.0906 30.3522L53.367 31.4376H52.0625H44.75H43.4455L42.7219 30.3522L35 18.7694L27.2781 30.3522L26.5545 31.4376H25.25H17.9375H16.633L15.9094 30.3522L11.0344 23.0397L10.133 21.6876L11.0344 20.3355L13.383 16.8126H10.625H6.09657ZM35 29L30.125 36.3125L35 43.625L39.875 36.3125L35 29Z"
                        fill="currentColor"
                      />
                    </svg>
                    <h2>{Object.keys(this.props.votingMoment)}</h2>
                  </div>
                  <div>
                    <div className="category-candidates">
                      {candidatesCards}
                      <div className="btn-area">
                        {this.props.chosen ? (
                          <button
                            onClick={() => this.handleVote()}
                            className="cs-cta cta-button mg-top-30"
                            type="button"
                          >
                            Votar
                          </button>
                        ) : (
                          <button
                            className="inactive-button cta-button mg-top-30"
                            type="button"
                          >
                            Aguardando voto
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="steps-wrapper">
                      <Stepper
                        votingMoment={Object.keys(this.props.votingMoment)}
                        steps={this.props.steps}
                        momentIndex={this.props.momentIndex}
                        game="CS"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }

      if (game === GAME_TAG.VALORANT) {
        if (this.props.errorMessage) {
          return <LoginMGBtn game={GAME_TAG.VALORANT} />;
        }
        return (
          <section
            className="pd-50 voting-container valorant-voting"
            id="voting"
          >
            <div className="section-title">
              <h1>A decisão agora é sua!</h1>
            </div>
            <div>
              <hr className="valorant-hr" />
            </div>
            <div className="section-description valorant-section-description">
              <p>
                Clique no seu candidato favorito. Em seguida, clique no botão{" "}
                <strong>VOTAR</strong>.<br />
                Após escolher, você pode voltar para a categoria desejada
                clicando sobre ela na linha do tempo do lado direito da página.
                Você também poderá revisar seus votos e alterar suas escolhas na
                etapa de <strong>REVISÃO</strong>.
              </p>
            </div>
            <div className="container">
              <div className="voting">
                <div className="candidates">
                  <div className="award-category valorant-category">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="70"
                      height="54"
                      viewBox="0 0 70 54"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.6875 11.9375L35 0.96875L42.3125 11.9375L39.3085 16.4435L46.0545 26.5626H50.758L54.008 21.6876L50.7602 16.8159L54.0124 11.9376H61.8125H66.6875H69.4716L69.1036 14.6972L64.2286 51.2598L63.9466 53.3751H61.8125H8.1875H6.05343L5.77138 51.2598L0.89638 14.6972L0.528427 11.9376H3.3125H10.625H15.9876L19.2398 16.8159L15.992 21.6876L19.242 26.5626H23.9455L30.6915 16.4435L27.6875 11.9375ZM6.09657 16.8126L10.3216 48.5001H59.6784L63.9034 16.8126H61.8125H56.617L58.9656 20.3355L59.867 21.6876L58.9656 23.0397L54.0906 30.3522L53.367 31.4376H52.0625H44.75H43.4455L42.7219 30.3522L35 18.7694L27.2781 30.3522L26.5545 31.4376H25.25H17.9375H16.633L15.9094 30.3522L11.0344 23.0397L10.133 21.6876L11.0344 20.3355L13.383 16.8126H10.625H6.09657ZM35 29L30.125 36.3125L35 43.625L39.875 36.3125L35 29Z"
                        fill="currentColor"
                      />
                    </svg>
                    <h2>{Object.keys(this.props.votingMoment)}</h2>
                  </div>
                  <div>
                    <div className="category-candidates">
                      {candidatesCards}
                      <div className="btn-area">
                        {this.props.chosen ? (
                          <button
                            onClick={() => this.handleVote()}
                            className="valorant-cta cta-button mg-top-30"
                            type="button"
                          >
                            Votar
                          </button>
                        ) : (
                          <button
                            className="inactive-button cta-button mg-top-30"
                            type="button"
                          >
                            Aguardando voto
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="steps-wrapper">
                      <Stepper
                        votingMoment={Object.keys(this.props.votingMoment)}
                        steps={this.props.steps}
                        momentIndex={this.props.momentIndex}
                        game="Valorant"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }

      return <></>;
    };

    return (
      <Countdown
        date={endVotingDate.get(game)}
        intervalDelay={60000}
        renderer={renderCountdown}
      />
    );
  }
}

const Voting = connect(mapStateToProps, mapDispatchToProps)(ConnectedVoting);

export default Voting;
