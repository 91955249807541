import {
  SELECT_GAME,
  CHOOSE,
  HAS_VOTED_FOR_VALORANT,
  HAS_VOTED_FOR_CS,
  SET_ERROR_MESSAGE,
} from "../constants/action-types";

// APP ACTIONS

export function choose(payload) {
  return { type: CHOOSE, payload };
}

export function selectGame(payload) {
  return { type: SELECT_GAME, payload };
}

export function confirmedVotesForCS(payload) {
  return { type: HAS_VOTED_FOR_CS, payload };
}

export function confirmedVotesForValorant(payload) {
  return { type: HAS_VOTED_FOR_VALORANT, payload };
}

export function setErrorMessage(payload) {
  return { type: SET_ERROR_MESSAGE, payload };
}
