import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";

import Judges from "../components/Judges";
import SponsorsArea from "../components/SponsorsArea";
import ChoicesReview from "../components/ChoicesReview";
import GameSwitch from "../components/GameSwitch";
import { Footer } from "../components/Footer";

import { indicated as indicatedCSGO } from "../constants/csgo/indicated";
import {
  CSGO_TOTAL_CATEGORIES,
  categories as categoriesCSGO,
} from "../constants/csgo/categories";
import { GAME_TAG } from "../constants/game";
import { GC_AWARDS_API } from "../constants/api";

import {
  selectGame,
  confirmedVotesForCS,
  voteForCsgoAssault,
  voteForCsgoAwp,
  voteForCsgoCaptain,
  voteForCsgoCoach,
  voteForCsgoOrg,
  voteForCsgoForeignPlayer,
  voteForCsgoMalePlayer,
  voteForCsgoFemalePlayer,
  voteForCsgoNewPlayer,
  voteForCsgoInfluencer,
  voteForCsgoLeaguePlayer,
  voteForCsgoLeagueOrg,
  voteForCsgoYoungPlayer,
  setErrorMessage,
} from "../actions/index";

import "../css/csgo.css";
import Voting from "../components/Voting";
import PremiereEvent from "../components/PremiereEvent";
import Winners from "../components/Winners";

function mapStateToProps(state) {
  return {
    hasVotedForCS: state.app.hasVotedForCS,
    assault: state.csgo.assault,
    awp: state.csgo.awp,
    captain: state.csgo.captain,
    coach: state.csgo.coach,
    organization: state.csgo.organization,
    newPlayer: state.csgo.newPlayer,
    foreignPlayer: state.csgo.foreignPlayer,
    malePlayer: state.csgo.malePlayer,
    femalePlayer: state.csgo.femalePlayer,
    influencer: state.csgo.influencer,
    leaguePlayer: state.csgo.leaguePlayer,
    leagueOrg: state.csgo.leagueOrg,
    youngPlayer: state.csgo.youngPlayer,
    expired: state.app.expired,
    finished: state.app.finished,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectGame: (game) => dispatch(selectGame(game)),
    confirmedVotesForCS: (hasVotedForCS) =>
      dispatch(confirmedVotesForCS(hasVotedForCS)),
    voteForCsgoAssault: (assault) => dispatch(voteForCsgoAssault(assault)),
    voteForCsgoAwp: (awp) => dispatch(voteForCsgoAwp(awp)),
    voteForCsgoCaptain: (captain) => dispatch(voteForCsgoCaptain(captain)),
    voteForCsgoCoach: (coach) => dispatch(voteForCsgoCoach(coach)),
    voteForCsgoOrg: (org) => dispatch(voteForCsgoOrg(org)),
    voteForCsgoNewPlayer: (newPlayer) =>
      dispatch(voteForCsgoNewPlayer(newPlayer)),
    voteForCsgoForeignPlayer: (foreignPlayer) =>
      dispatch(voteForCsgoForeignPlayer(foreignPlayer)),
    voteForCsgoMalePlayer: (malePlayer) =>
      dispatch(voteForCsgoMalePlayer(malePlayer)),
    voteForCsgoFemalePlayer: (femalePlayer) =>
      dispatch(voteForCsgoFemalePlayer(femalePlayer)),
    voteForCsgoInfluencer: (influencer) =>
      dispatch(voteForCsgoInfluencer(influencer)),
    setErrorMessage: (errorMessage) => dispatch(setErrorMessage(errorMessage)),
    voteForCsgoLeaguePlayer: (leaguePlayer) =>
      dispatch(voteForCsgoLeaguePlayer(leaguePlayer)),
    voteForCsgoLeagueOrg: (leagueOrg) =>
      dispatch(voteForCsgoLeagueOrg(leagueOrg)),
    voteForCsgoYoungPlayer: (youngPlayer) =>
      dispatch(voteForCsgoYoungPlayer(youngPlayer)),
  };
}

class ConnectedCsGo extends Component {
  componentDidMount() {
    this.props.selectGame("CsGo");
    this.props.setErrorMessage("");

    const cookies = new Cookies();

    if (cookies.get("gcmg:refreshToken")) {
      const authToken = cookies.get("gcmg:refreshToken");
      fetch(`${GC_AWARDS_API}/csgo`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
        .catch((e) => console.log(e))
        .then((res) => res.json())
        .then((data) => {
          if (!data.userAuthenticated) {
            this.props.setErrorMessage(
              "Falha ao validar sessão. Por favor, certifique-se de que você está logado."
            );
            ReactGA.event({
              category: "Action",
              action: "View",
              label: "Usuário acessou a página sem estar logado.",
            });
          } else {
            if (data.userHasVoted) {
              this.props.confirmedVotesForCS(true);
              this.props.voteForCsgoAssault(data.userVotes.assault);
              this.props.voteForCsgoAwp(data.userVotes.awp);
              this.props.voteForCsgoCaptain(data.userVotes.captain);
              this.props.voteForCsgoCoach(data.userVotes.coach);
              this.props.voteForCsgoOrg(data.userVotes.organization);
              this.props.voteForCsgoNewPlayer(data.userVotes.newPlayer);
              this.props.voteForCsgoForeignPlayer(data.userVotes.foreignPlayer);
              this.props.voteForCsgoMalePlayer(data.userVotes.malePlayer);
              this.props.voteForCsgoFemalePlayer(data.userVotes.femalePlayer);
              this.props.voteForCsgoInfluencer(data.userVotes.influencer);
              this.props.voteForCsgoLeaguePlayer(data.userVotes.leaguePlayer);
              this.props.voteForCsgoLeagueOrg(data.userVotes.leagueOrg);
              this.props.voteForCsgoYoungPlayer(data.userVotes.youngPlayer);
            } else {
              this.props.confirmedVotesForCS(false);
            }
          }
        });
    } else {
      this.props.setErrorMessage(
        "Falha ao validar sessão. Por favor, certifique-se de que você está logado."
      );
    }
  }

  render() {
    let {
      assault,
      awp,
      captain,
      coach,
      organization,
      newPlayer,
      foreignPlayer,
      femalePlayer,
      malePlayer,
      influencer,
      leaguePlayer,
      leagueOrg,
      youngPlayer,
      hasVotedForCS,
    } = this.props;

    let picks = [
      assault,
      awp,
      captain,
      coach,
      organization,
      newPlayer,
      foreignPlayer,
      femalePlayer,
      malePlayer,
      influencer,
      leaguePlayer,
      leagueOrg,
      youngPlayer,
      "",
    ];

    const categories = {
      ...Object.entries(categoriesCSGO).reduce((accumlate, current) => {
        return {
          ...accumlate,
          [current[1]]: indicatedCSGO.filter(
            (indicado) => indicado.categoria === current[0]
          ),
        };
      }, {}),
      Revisão: [{}],
    };

    let momentIndex = 0;

    let getIndex = () => {
      momentIndex = picks.findIndex((x) => x === "");
      let momentKey = Object.keys(categories)[momentIndex];
      let momentValues = Object.values(categories)[momentIndex];
      return { [momentKey]: momentValues };
    };

    let votingMoment = getIndex();

    return (
      <div>
        <GameSwitch game={GAME_TAG.CS} />
        <SponsorsArea game={GAME_TAG.CS} />
        {this.props.finished && <Winners game={GAME_TAG.CS} />}
        <Judges game={GAME_TAG.CS} />
        {!this.props.finished && (
          <PremiereEvent game={GAME_TAG.CS} hasVoted={hasVotedForCS} />
        )}
        {momentIndex === CSGO_TOTAL_CATEGORIES &&
          !this.props.expired &&
          !this.props.finished && (
            <ChoicesReview
              votingMoment={votingMoment}
              steps={Object.keys(categories)}
              momentIndex={CSGO_TOTAL_CATEGORIES}
              candidates={categories}
              game={GAME_TAG.CS}
            />
          )}
        {momentIndex < CSGO_TOTAL_CATEGORIES &&
          momentIndex > -1 &&
          !this.props.expired &&
          !this.props.finished && (
            <Voting
              votingMoment={votingMoment}
              steps={Object.keys(categories)}
              momentIndex={momentIndex}
              game={GAME_TAG.CS}
            />
          )}
        <Footer game={GAME_TAG.CS} />
      </div>
    );
  }
}

const CsGo = connect(mapStateToProps, mapDispatchToProps)(ConnectedCsGo);

export default CsGo;
