import React, { Component } from "react";
import { connect } from "react-redux";

import {
  voteForCsgoAssault,
  voteForCsgoAwp,
  voteForCsgoCaptain,
  voteForCsgoCoach,
  voteForCsgoOrg,
  voteForCsgoForeignPlayer,
  voteForCsgoMalePlayer,
  voteForCsgoFemalePlayer,
  voteForCsgoNewPlayer,
  voteForCsgoInfluencer,
  voteForCsgoLeaguePlayer,
  voteForCsgoLeagueOrg,
  voteForCsgoYoungPlayer,
  voteForValorantInitiator,
  voteForValorantController,
  voteForValorantDuelist,
  voteForValorantSentinel,
  voteForValorantCaptain,
  voteForValorantCoach,
  voteForValorantOrg,
  voteForValorantFemalePlayer,
  voteForValorantMalePlayer,
  voteForValorantInfluencer,
  choose,
  selectGame,
  confirmedVotesForCS,
  confirmedVotesForValorant,
  setErrorMessage,
} from "../actions/index";
import { categories as categoriesCSGO } from "../constants/csgo/categories";
import { categories as categoriesValorant } from "../constants/valorant/categories";

const mapStateToProps = (state) => {
  return {
    chosen: state.app.chosen,
    hasVotedForValorant: state.app.hasVotedForValorant,
    hasVotedForCS: state.app.hasVotedForCS,
    assault: state.csgo.assault,
    awp: state.csgo.awp,
    csGoCaptain: state.csgo.captain,
    csGoCoach: state.csgo.coach,
    csGoOrganization: state.csgo.organization,
    newPlayer: state.csgo.newPlayer,
    foreignPlayer: state.csgo.foreignPlayer,
    csGoMalePlayer: state.csgo.malePlayer,
    csGoFemalePlayer: state.csgo.femalePlayer,
    csGoInfluencer: state.csgo.influencer,
    csGoLeaguePlayer: state.csgo.leaguePlayer,
    csGoLeagueOrg: state.csgo.leagueOrg,
    csGoYoungPlayer: state.csgo.YoungPlayer,
    initiator: state.valorant.initiator,
    controller: state.valorant.controller,
    duelist: state.valorant.duelist,
    sentinel: state.valorant.sentinel,
    valorantCaptain: state.valorant.captain,
    valorantCoach: state.valorant.coach,
    valorantOrganization: state.valorant.organization,
    valorantFemalePlayer: state.valorant.femalePlayer,
    valorantMalePlayer: state.valorant.malePlayer,
    valorantInfluencer: state.valorant.influencer,
    errorMessage: state.app.errorMessage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    voteForCsgoAssault: (assault) => dispatch(voteForCsgoAssault(assault)),
    voteForCsgoAwp: (awp) => dispatch(voteForCsgoAwp(awp)),
    voteForCsgoCaptain: (captain) => dispatch(voteForCsgoCaptain(captain)),
    voteForCsgoCoach: (coach) => dispatch(voteForCsgoCoach(coach)),
    voteForCsgoOrg: (org) => dispatch(voteForCsgoOrg(org)),
    voteForCsgoNewPlayer: (newPlayer) =>
      dispatch(voteForCsgoNewPlayer(newPlayer)),
    voteForCsgoForeignPlayer: (foreignPlayer) =>
      dispatch(voteForCsgoForeignPlayer(foreignPlayer)),
    voteForCsgoMalePlayer: (malePlayer) =>
      dispatch(voteForCsgoMalePlayer(malePlayer)),
    voteForCsgoFemalePlayer: (femalePlayer) =>
      dispatch(voteForCsgoFemalePlayer(femalePlayer)),
    voteForCsgoInfluencer: (influencer) =>
      dispatch(voteForCsgoInfluencer(influencer)),
    voteForCsgoLeaguePlayer: (leaguePlayer) =>
      dispatch(voteForCsgoLeaguePlayer(leaguePlayer)),
    voteForCsgoLeagueOrg: (leagueOrg) =>
      dispatch(voteForCsgoLeagueOrg(leagueOrg)),
    voteForCsgoYoungPlayer: (youngPlayer) =>
      dispatch(voteForCsgoYoungPlayer(youngPlayer)),
    voteForValorantInitiator: (initiator) =>
      dispatch(voteForValorantInitiator(initiator)),
    voteForValorantController: (controller) =>
      dispatch(voteForValorantController(controller)),
    voteForValorantDuelist: (duelist) =>
      dispatch(voteForValorantDuelist(duelist)),
    voteForValorantSentinel: (sentinel) =>
      dispatch(voteForValorantSentinel(sentinel)),
    voteForValorantCaptain: (captain) =>
      dispatch(voteForValorantCaptain(captain)),
    voteForValorantCoach: (coach) => dispatch(voteForValorantCoach(coach)),
    voteForValorantOrg: (organization) =>
      dispatch(voteForValorantOrg(organization)),
    voteForValorantFemalePlayer: (femalePlayer) =>
      dispatch(voteForValorantFemalePlayer(femalePlayer)),
    voteForValorantMalePlayer: (malePlayer) =>
      dispatch(voteForValorantMalePlayer(malePlayer)),
    voteForValorantInfluencer: (influencer) =>
      dispatch(voteForValorantInfluencer(influencer)),
    selectGame: (game) => dispatch(selectGame(game)),
    confirmedVotesForCS: (hasVotedForCS) =>
      dispatch(confirmedVotesForCS(hasVotedForCS)),
    confirmedVotesForValorant: (hasVotedForValorant) =>
      dispatch(confirmedVotesForValorant(hasVotedForValorant)),
    setErrorMessage: (errorMessage) => dispatch(setErrorMessage(errorMessage)),
    choose: (chosen) => dispatch(choose(chosen)),
  };
}
class ConnectedStepper extends Component {
  handleReview(item) {
    let { game } = this.props;

    if (!this.props.hasVotedForCS) {
      if (game === "CS") {
        switch (item) {
          case categoriesCSGO.MELHOR_ASSAULT:
            this.props.choose("");
            this.props.voteForCsgoAssault("");
            break;
          case categoriesCSGO.MELHOR_AWP:
            this.props.choose("");
            this.props.voteForCsgoAwp("");
            break;
          case categoriesCSGO.MELHOR_CAPITAO:
            this.props.choose("");
            this.props.voteForCsgoCaptain("");
            break;
          case categoriesCSGO.MELHOR_COACH:
            this.props.choose("");
            this.props.voteForCsgoCoach("");
            break;
          case categoriesCSGO.MELHOR_ORGANIZACAO:
            this.props.choose("");
            this.props.voteForCsgoOrg("");
            break;
          case categoriesCSGO.REVELACAO:
            this.props.choose("");
            this.props.voteForCsgoNewPlayer("");
            break;
          case categoriesCSGO.ESTRANGEIRO_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoForeignPlayer("");
            break;
          case categoriesCSGO.JOGADORA_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoFemalePlayer("");
            break;
          case categoriesCSGO.JOGADOR_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoMalePlayer("");
            break;
          case categoriesCSGO.PERSONALIDADE_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoInfluencer("");
            break;
          case categoriesCSGO.JOGADOR_LIGAS:
            this.props.choose("");
            this.props.voteForCsgoLeaguePlayer("");
            break;
          case categoriesCSGO.ORGANIZACAO_LIGAS:
            this.props.choose("");
            this.props.voteForCsgoLeagueOrg("");
            break;
          case categoriesCSGO.JOVEM_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoYoungPlayer("");
            break;
          default:
            return null;
        }
      }
    }

    if (!this.props.hasVotedForValorant) {
      if (game === "Valorant") {
        switch (item) {
          case categoriesValorant.MELHOR_INICIADOR:
            this.props.choose("");
            this.props.voteForValorantInitiator("");
            break;
          case categoriesValorant.MELHOR_CONTROLADOR:
            this.props.choose("");
            this.props.voteForValorantController("");
            break;
          case categoriesValorant.MELHOR_DUELISTA:
            this.props.choose("");
            this.props.voteForValorantDuelist("");
            break;
          case categoriesValorant.MELHOR_SENTINELA:
            this.props.choose("");
            this.props.voteForValorantSentinel("");
            break;
          case categoriesValorant.MELHOR_CAPITAO:
            this.props.choose("");
            this.props.voteForValorantCaptain("");
            break;
          case categoriesValorant.MELHOR_COACH:
            this.props.choose("");
            this.props.voteForValorantCoach("");
            break;
          case categoriesValorant.MELHOR_ORGANIZACAO:
            this.props.choose("");
            this.props.voteForValorantOrg("");
            break;
          case categoriesValorant.JOGADORA_DO_ANO:
            this.props.choose("");
            this.props.voteForValorantFemalePlayer("");
            break;
          case categoriesValorant.JOGADOR_DO_ANO:
            this.props.choose("");
            this.props.voteForValorantMalePlayer("");
            break;
          case categoriesValorant.PERSONALIDADE_DO_ANO:
            this.props.choose("");
            this.props.voteForValorantInfluencer("");
            break;
          default:
            return null;
        }
      }
    }
  }

  render() {
    let { steps, votingMoment, momentIndex, game } = this.props;
    let stepperStructure = [];

    if (game === "CS") {
      steps.forEach((item, index) => {
        if (item === votingMoment[0]) {
          stepperStructure.push(
            <div
              className="stepper"
              key={Math.random().toString(36).substring(2)}
            >
              <div className="step-line">
                <div className="step-fig">
                  <div className="outside-circle">
                    <div className="cs-inner-c inner-circle active-circle"></div>
                  </div>
                </div>
                <p className="cs-active-p active-p">{item}</p>
              </div>
              {index < steps.length - 1 && <div className="vert-line"></div>}
            </div>
          );
        } else {
          stepperStructure.push(
            <div
              className="stepper"
              key={Math.random().toString(36).substring(2)}
            >
              <div className="step-line">
                <div className="step-fig">
                  <div className="outside-circle">
                    {momentIndex > index ? (
                      <div className="cs-inner-c inner-circle inactive-circle"></div>
                    ) : (
                      <div className="inner-circle unchecked"></div>
                    )}
                  </div>
                </div>
                {momentIndex < index ? (
                  <p className="unchecked-p">{item}</p>
                ) : (
                  <p
                    onClick={() => this.handleReview(item)}
                    className="inactive-p"
                  >
                    {item}
                  </p>
                )}
              </div>
              {index < steps.length - 1 && <div className="vert-line"></div>}
            </div>
          );
        }
      });
    }

    if (game === "Valorant") {
      steps.forEach((item, index) => {
        if (item === votingMoment[0]) {
          stepperStructure.push(
            <div
              className="stepper"
              key={Math.random().toString(36).substring(2)}
            >
              <div className="step-line">
                <div className="step-fig">
                  <div className="outside-circle">
                    <div className="valorant-inner-c inner-circle active-circle"></div>
                  </div>
                </div>
                <p className="valorant-active-p active-p">{item} </p>
              </div>
              {index < steps.length - 1 && <div className="vert-line"></div>}
            </div>
          );
        } else {
          stepperStructure.push(
            <div
              className="stepper"
              key={Math.random().toString(36).substring(2)}
            >
              <div className="step-line">
                <div className="step-fig">
                  <div className="outside-circle">
                    {momentIndex > index ? (
                      <div className="valorant-inner-c inner-circle inactive-circle"></div>
                    ) : (
                      <div className="inner-circle unchecked"></div>
                    )}
                  </div>
                </div>
                {momentIndex < index ? (
                  <p className="unchecked-p">{item} </p>
                ) : (
                  <p
                    onClick={() => this.handleReview(item)}
                    className="inactive-p"
                  >
                    {item}
                  </p>
                )}
              </div>
              {index < steps.length - 1 && <div className="vert-line"></div>}
            </div>
          );
        }
      });
    }

    return <div>{stepperStructure}</div>;
  }
}

const Stepper = connect(mapStateToProps, mapDispatchToProps)(ConnectedStepper);

export default Stepper;
