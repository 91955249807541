export const playersIndicated = [
  // ================================================================================================
  // ==== CS2 =======================================================================================
  // ================================================================================================
  // MELHOR_ASSAULT
  {
    name: "Venomzera",
    org: "RED Canids",
    twitter: "https://twitter.com/venomzeracs",
    twitch: "",
    instagram: "https://www.instagram.com/venomzeracs",
    categoria: "MELHOR_ASSAULT",
    jogo: "CSGO",
  },
  {
    name: "Lucaozy",
    org: "Fluxo",
    twitter: "https://twitter.com/Lucaozy",
    twitch: "https://www.twitch.tv/lucaozyfps",
    instagram: "https://www.instagram.com/lucaozy/",
    categoria: "MELHOR_ASSAULT",
    jogo: "CSGO",
  },
  {
    name: "Dav1deus",
    org: "RED Canids",
    twitter: "https://twitter.com/dav1deuS_",
    twitch: "",
    instagram: "https://www.instagram.com/dav1deus_",
    categoria: "MELHOR_ASSAULT",
    jogo: "CSGO",
  },
  {
    name: "dgt",
    org: "9z Team",
    twitter: "https://twitter.com/dgtcsgo",
    twitch: "https://www.twitch.tv/dgtcsgo",
    instagram: "https://www.instagram.com/frandgt_",
    categoria: "MELHOR_ASSAULT",
    jogo: "CSGO",
  },
  // MELHOR_AWP
  {
    name: "zevy",
    org: "Fluxo",
    twitter: "https://twitter.com/zevyfps",
    twitch: "https://www.twitch.tv/zevy",
    instagram: "https://www.instagram.com/zevyfps",
    categoria: "MELHOR_AWP",
    jogo: "CSGO",
  },
  {
    name: "HEN1",
    org: "Imperial",
    twitter: "https://twitter.com/Hen1",
    twitch: "https://www.twitch.tv/henriquehen1",
    instagram: "https://www.instagram.com/henriquehen1",
    categoria: "MELHOR_AWP",
    jogo: "CSGO",
  },
  {
    name: "gafolo",
    org: "Sharks",
    twitter: "https://twitter.com/gafolo1",
    twitch: "",
    instagram: "",
    categoria: "MELHOR_AWP",
    jogo: "CSGO",
  },
  {
    name: "try",
    org: "9z",
    twitter: "https://twitter.com/tryRSS",
    twitch: "https://www.youtube.com/channel/UCiZozClBHELAuJB0MCOk5fw",
    instagram: "",
    categoria: "MELHOR_AWP",
    jogo: "CSGO",
  },
  // MELHOR_CAPITAO
  {
    name: "biguzera",
    org: "paiN",
    twitter: "https://twitter.com/biguzera1",
    twitch: "https://www.twitch.tv/biguzera1",
    instagram: "https://www.instagram.com/biguzera1",
    categoria: "MELHOR_CAPITAO",
    jogo: "CSGO",
  },
  {
    name: "Noktse",
    org: "BESTIA",
    twitter: "https://twitter.com/noktseCS",
    twitch: "",
    instagram: "https://www.instagram.com/noktsecs",
    categoria: "MELHOR_CAPITAO",
    jogo: "CSGO",
  },
  {
    name: "max",
    org: "9z",
    twitter: "https://twitter.com/maxgzez",
    twitch: "",
    instagram: "https://www.instagram.com/maxgzez",
    categoria: "MELHOR_CAPITAO",
    jogo: "CSGO",
  },
  {
    name: "VINI",
    org: "Imperial",
    twitter: "https://twitter.com/vini",
    twitch: "https://www.twitch.tv/vinicsgo",
    instagram: "https://www.instagram.com/vini_csgo",
    categoria: "MELHOR_CAPITAO",
    jogo: "CSGO",
  },
  // MELHOR_COACH
  {
    name: "zakk",
    org: "Imperial",
    twitter: "https://twitter.com/zakkfps",
    twitch: "",
    instagram: "https://www.instagram.com/rafaxfernandes",
    categoria: "MELHOR_COACH",
    jogo: "CSGO",
  },
  {
    name: "righi",
    org: "RED Canids",
    twitter: "https://twitter.com/righifps",
    twitch: "",
    instagram: "https://www.instagram.com/righifps",
    categoria: "MELHOR_COACH",
    jogo: "CSGO",
  },
  {
    name: "tge",
    org: "9z",
    twitter: "https://twitter.com/tgefps",
    twitch: "",
    instagram: "https://www.instagram.com/tge_motta",
    categoria: "MELHOR_COACH",
    jogo: "CSGO",
  },
  {
    name: "rikz",
    org: "paiN",
    twitter: "https://twitter.com/henriquerikz",
    twitch: "",
    instagram: "https://www.instagram.com/henriquerikz",
    categoria: "MELHOR_COACH",
    jogo: "CSGO",
  },
  // MELHOR_ORGANIZACAO
  {
    name: "Fluxo",
    org: "",
    twitter: "https://twitter.com/fluxogg",
    twitch: "",
    instagram: "https://www.instagram.com/fluxogg/",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "CSGO",
  },
  {
    name: "FURIA",
    org: "",
    twitter: "https://twitter.com/FURIA",
    twitch: "https://www.twitch.tv/furiatv",
    instagram: "https://www.instagram.com/furiagg/",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "CSGO",
  },
  {
    name: "Imperial",
    org: "",
    twitter: "https://twitter.com/imperialesports",
    twitch: "",
    instagram: "https://www.instagram.com/imperialesports/",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "CSGO",
  },
  {
    name: "paiN",
    org: "",
    twitter: "https://twitter.com/paiNGamingBR",
    twitch: "https://www.twitch.tv/paingamingbr",
    instagram: "https://www.instagram.com/paingamingbr/",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "CSGO",
  },
  // REVELACAO
  {
    name: "noway",
    org: "Imperial",
    twitter: "https://twitter.com/nowayzao",
    twitch: "https://www.twitch.tv/nowayzao",
    instagram: "https://www.instagram.com/nowayzao",
    categoria: "REVELACAO",
    jogo: "CSGO",
  },
  {
    name: "decenty",
    org: "Imperial",
    twitter: "https://twitter.com/decentycs",
    twitch: "https://www.twitch.tv/decentycs",
    instagram: "https://www.instagram.com/decentycs",
    categoria: "REVELACAO",
    jogo: "CSGO",
  },
  {
    name: "saadzin",
    org: "W7M",
    twitter: "https://twitter.com/saadzin_fps",
    twitch: "",
    instagram: "",
    categoria: "REVELACAO",
    jogo: "CSGO",
  },
  {
    name: "tomaszin",
    org: "BESTIA",
    twitter: "https://twitter.com/tomaszincs",
    twitch: "https://www.twitch.tv/tomaszincs",
    instagram: "https://www.instagram.com/tomascorna/",
    categoria: "REVELACAO",
    jogo: "CSGO",
  },
  // ESTRANGEIRO_DO_ANO
  {
    name: "Dav1deus",
    org: "RED Canids",
    twitter: "https://twitter.com/dav1deuS_",
    twitch: "",
    instagram: "https://www.instagram.com/dav1deus_",
    categoria: "ESTRANGEIRO_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "luchov",
    org: "BESTIA",
    twitter: "https://twitter.com/luchov_cs",
    twitch: "",
    instagram: "https://www.instagram.com/luchovcs",
    categoria: "ESTRANGEIRO_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "dgt",
    org: "9z Team",
    twitter: "https://twitter.com/dgtcsgo",
    twitch: "https://www.twitch.tv/dgtcsgo",
    instagram: "https://www.instagram.com/frandgt_",
    categoria: "ESTRANGEIRO_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "Tomaszin",
    org: "BESTIA",
    twitter: "https://twitter.com/tomaszincs",
    twitch: "https://www.twitch.tv/tomaszincs",
    instagram: "https://www.instagram.com/tomascorna/",
    categoria: "ESTRANGEIRO_DO_ANO",
    jogo: "CSGO",
  },
  // JOGADORA_DO_ANO
  {
    name: "Yungher",
    org: "Fluxo Demons",
    twitter: "https://twitter.com/yungherr",
    twitch: "https://www.twitch.tv/yungherr",
    instagram: "https://www.instagram.com/yungherr/",
    categoria: "JOGADORA_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "Kaahsensei",
    org: "FURIA fe",
    twitter: "https://twitter.com/kaahtak",
    twitch: "https://www.twitch.tv/kaah/",
    instagram: "https://www.instagram.com/kaahtak/",
    categoria: "JOGADORA_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "GaBi",
    org: "FURIA fe",
    twitter: "https://twitter.com/gabiifps",
    twitch: "https://www.twitch.tv/gabiifps",
    instagram: "https://www.instagram.com/gabicsgo",
    categoria: "JOGADORA_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "poppins",
    org: "Fluxo Demons",
    twitter: "https://twitter.com/poppinscs",
    twitch: "",
    instagram: "https://www.instagram.com/poppinscs",
    categoria: "JOGADORA_DO_ANO",
    jogo: "CSGO",
  },
  // JOGADOR_DO_ANO
  {
    name: "dgt",
    org: "9z",
    twitter: "https://twitter.com/dgtcsgo",
    twitch: "https://www.twitch.tv/dgtcsgo",
    instagram: "https://www.instagram.com/frandgt_",
    categoria: "JOGADOR_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "lucaozy",
    org: "Fluxo",
    twitter: "https://twitter.com/Lucaozy",
    twitch: "https://www.twitch.tv/lucaozyfps",
    instagram: "https://www.instagram.com/lucaozy/",
    categoria: "JOGADOR_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "biguzera",
    org: "paiN",
    twitter: "https://twitter.com/biguzera1",
    twitch: "https://www.twitch.tv/biguzera1",
    instagram: "https://www.instagram.com/biguzera1",
    categoria: "JOGADOR_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "venomzera",
    org: "RED Canids",
    twitter: "https://twitter.com/venomzeracs",
    twitch: "",
    instagram: "https://www.instagram.com/venomzeracs",
    categoria: "JOGADOR_DO_ANO",
    jogo: "CSGO",
  },
  // PERSONALIDADE_DO_ANO
  {
    name: "Gaules",
    org: "Tribo",
    twitter: "https://twitter.com/Gaules",
    twitch: "https://www.twitch.tv/gaules",
    instagram: "https://www.instagram.com/gaules/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "bt0",
    org: "Tribo",
    twitter: "https://twitter.com/fillipebt",
    twitch: "https://www.twitch.tv/bt0tv",
    instagram: "https://www.instagram.com/fillipebt/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "mch",
    org: "Tribo",
    twitter: "https://twitter.com/mch_AGG",
    twitch: "https://www.twitch.tv/michel",
    instagram: "https://www.instagram.com/mch_agg/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "Liminha",
    org: "Tribo",
    twitter: "https://twitter.com/andrekenzo11",
    twitch: "https://www.twitch.tv/liminhag0d",
    instagram: "https://www.instagram.com/liminhagg",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "CSGO",
  },
  // JOGADOR_LIGAS
  {
    name: "card",
    org: "MIBR Academy",
    twitter: "https://twitter.com/cardfps",
    twitch: "https://www.twitch.tv/cardfps",
    instagram: "https://www.instagram.com/cardfps_/",
    categoria: "JOGADOR_LIGAS",
    jogo: "CSGO",
  },
  {
    name: "saadzin",
    org: "W7M",
    twitter: "https://twitter.com/saadzin_fps",
    twitch: "",
    instagram: "",
    categoria: "JOGADOR_LIGAS",
    jogo: "CSGO",
  },
  {
    name: "cerolzin",
    org: "BeBold",
    twitter: "https://twitter.com/cerol011",
    twitch: "https://www.twitch.tv/cerol011",
    instagram: "https://www.instagram.com/diogoterto_",
    categoria: "JOGADOR_LIGAS",
    jogo: "CSGO",
  },
  {
    name: "bnc",
    org: "BeBold",
    twitter: "https://twitter.com/bnccsgo",
    twitch: "https://www.twitch.tv/bnccs",
    instagram: "https://www.instagram.com/pedrosahdoo_",
    categoria: "JOGADOR_LIGAS",
    jogo: "CSGO",
  },
  // ORGANIZACAO_LIGAS
  {
    name: "BeBold",
    org: "BeBold",
    twitter: "https://twitter.com/_Beboldgg",
    twitch: "https://www.twitch.tv/beboldgg",
    instagram: "https://www.instagram.com/bebold.gg/",
    categoria: "ORGANIZACAO_LIGAS",
    jogo: "CSGO",
  },
  {
    name: "AJF",
    org: "AJF",
    twitter: "",
    twitch: "",
    instagram: "https://www.instagram.com/ajfesports/",
    categoria: "ORGANIZACAO_LIGAS",
    jogo: "CSGO",
  },
  {
    name: "Intense",
    org: "Intense",
    twitter: "https://twitter.com/intensegamegg",
    twitch: "https://www.twitch.tv/intensegamee",
    instagram: "https://www.instagram.com/intensegamegg/",
    categoria: "ORGANIZACAO_LIGAS",
    jogo: "CSGO",
  },
  {
    name: "MIBR Academy",
    org: "MIBR Academy",
    twitter: "https://twitter.com/MIBR",
    twitch: "https://www.twitch.tv/mibrtv",
    instagram: "https://www.instagram.com/mibrteam/",
    categoria: "ORGANIZACAO_LIGAS",
    jogo: "CSGO",
    avatar: "mibr-academy",
  },
  // JOVEM_DO_ANO
  {
    name: "snow",
    org: "Case Esports",
    twitter: "https://twitter.com/Snowzincs",
    twitch: "",
    instagram: "",
    categoria: "JOVEM_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "poppins",
    org: "Fluxo Demons",
    twitter: "https://twitter.com/poppinscs",
    twitch: "",
    instagram: "https://www.instagram.com/poppinscs",
    categoria: "JOVEM_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "nqz",
    org: "paiN",
    twitter: "https://twitter.com/nqzcs",
    twitch: "",
    instagram: "",
    categoria: "JOVEM_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "noway",
    org: "Imperial",
    twitter: "https://twitter.com/nowayzao",
    twitch: "https://www.twitch.tv/nowayzao",
    instagram: "https://www.instagram.com/nowayzao",
    categoria: "JOVEM_DO_ANO",
    jogo: "CSGO",
  },
  // ================================================================================================
  // ==== VALORANT ==================================================================================
  // ================================================================================================
  // MELHOR_INICIADOR
  {
    name: "cauanzin",
    org: "LOUD",
    twitter: "https://twitter.com/cauanzinvlr",
    twitch: "",
    instagram: "http://instagram.com/cauanzinpd",
    categoria: "MELHOR_INICIADOR",
    jogo: "VALORANT",
  },
  {
    name: "Daiki",
    org: "Liquid",
    twitter: "https://twitter.com/daikifps",
    twitch: "https://www.twitch.tv/daikifpss",
    instagram: "https://www.instagram.com/daikifps/",
    categoria: "MELHOR_INICIADOR",
    jogo: "VALORANT",
  },
  {
    name: "ntk",
    org: "",
    twitter: "https://twitter.com/ntkvlr",
    twitch: "",
    instagram: "https://www.instagram.com/ntkvlr",
    categoria: "MELHOR_INICIADOR",
    jogo: "VALORANT",
  },
  {
    name: "mwzera",
    org: "FURIA",
    twitter: "https://twitter.com/mwzera",
    twitch: "https://www.twitch.tv/mwzera",
    instagram: "https://www.instagram.com/mwzera",
    categoria: "MELHOR_INICIADOR",
    jogo: "VALORANT",
  },
  // MELHOR_CONTROLADOR
  {
    name: "tuyz",
    org: "LOUD",
    twitter: "https://twitter.com/loud_tuyz",
    twitch: "https://www.twitch.tv/tuyzin",
    instagram: "https://www.instagram.com/loud_tuyz1",
    categoria: "MELHOR_CONTROLADOR",
    jogo: "VALORANT",
  },
  {
    name: "GuhRVN",
    org: "",
    twitter: "https://twitter.com/GuhRVN",
    twitch: "https://www.twitch.tv/guhrvn",
    instagram: "https://www.instagram.com/guhrvn/",
    categoria: "MELHOR_CONTROLADOR",
    jogo: "VALORANT",
  },
  {
    name: "Joojina",
    org: "Liquid",
    twitter: "https://twitter.com/joojinavlr",
    twitch: "https://www.twitch.tv/joojina",
    instagram: "https://www.instagram.com/joojinavlr",
    categoria: "MELHOR_CONTROLADOR",
    jogo: "VALORANT",
  },
  {
    name: "Khalil",
    org: "FURIA",
    twitter: "https://twitter.com/khalilfps",
    twitch: "https://www.twitch.tv/khalil_fps",
    instagram: "https://www.instagram.com/khalilfps/",
    categoria: "MELHOR_CONTROLADOR",
    jogo: "VALORANT",
  },
  // MELHOR_DUELISTA
  {
    name: "aspas",
    org: "Leviatán",
    twitter: "https://twitter.com/aspaszin",
    twitch: "https://www.twitch.tv/aspaszin",
    instagram: "https://www.instagram.com/aspaszin/",
    categoria: "MELHOR_DUELISTA",
    jogo: "VALORANT",
  },
  {
    name: "havoc",
    org: "FURIA",
    twitter: "https://twitter.com/havocvlr",
    twitch: "https://www.twitch.tv/havocfps1",
    instagram: "https://www.instagram.com/havocfps1",
    categoria: "MELHOR_DUELISTA",
    jogo: "VALORANT",
  },
  {
    name: "bstrdd",
    org: "Liquid",
    twitter: "https://twitter.com/bstrddgg",
    twitch: "https://www.twitch.tv/bstrdd",
    instagram: "https://www.instagram.com/bvstrdd",
    categoria: "MELHOR_DUELISTA",
    jogo: "VALORANT",
  },
  {
    name: "Askia",
    org: "",
    twitter: "https://twitter.com/askiazin",
    twitch: "https://www.twitch.tv/askiazin",
    instagram: "https://www.instagram.com/askiafps/",
    categoria: "MELHOR_DUELISTA",
    jogo: "VALORANT",
  },
  // MELHOR_SENTINELA
  {
    name: "Less",
    org: "LOUD",
    twitter: "https://twitter.com/loud_less",
    twitch: "https://www.twitch.tv/lessvlr",
    instagram: "https://www.instagram.com/lessvlr/",
    categoria: "MELHOR_SENTINELA",
    jogo: "VALORANT",
  },
  {
    name: "pancc",
    org: "",
    twitter: "https://twitter.com/panccfps",
    twitch: "",
    instagram: "https://www.instagram.com/panccfps",
    categoria: "MELHOR_SENTINELA",
    jogo: "VALORANT",
  },
  {
    name: "qck",
    org: "LOUD",
    twitter: "https://twitter.com/loud_qck",
    twitch: "",
    instagram: "https://www.instagram.com/qckvlr/",
    categoria: "MELHOR_SENTINELA",
    jogo: "VALORANT",
  },
  {
    name: "isaa",
    org: "Liquid",
    twitter: "https://twitter.com/isaavlr",
    twitch: "https://www.twitch.tv/isaavlr",
    instagram: "https://www.instagram.com/isab_esser",
    categoria: "MELHOR_SENTINELA",
    jogo: "VALORANT",
  },
  // MELHOR_CAPITAO
  {
    name: "Saadhak",
    org: "LOUD",
    twitter: "https://twitter.com/loud_saadhak",
    twitch: "https://www.twitch.tv/saadhak",
    instagram: "https://www.instagram.com/saadhak.gg/",
    categoria: "MELHOR_CAPITAO",
    jogo: "VALORANT",
  },
  {
    name: "raafa",
    org: "UNIÃO",
    twitter: "https://twitter.com/raafawp",
    twitch: "",
    instagram: "",
    categoria: "MELHOR_CAPITAO",
    jogo: "VALORANT",
  },
  {
    name: "Daiki",
    org: "Liquid",
    twitter: "https://twitter.com/daikifps",
    twitch: "https://www.twitch.tv/daikifpss",
    instagram: "https://www.instagram.com/daikifps/",
    categoria: "MELHOR_CAPITAO",
    jogo: "VALORANT",
  },
  {
    name: "Mazin",
    org: "MIBR",
    twitter: "https://twitter.com/mazinfps",
    twitch: "https://www.twitch.tv/mazinfps",
    instagram: "https://www.instagram.com/mazinfps",
    categoria: "MELHOR_CAPITAO",
    jogo: "VALORANT",
  },
  // MELHOR_COACH
  {
    name: "peu",
    org: "LOUD",
    twitter: "https://twitter.com/peu_lg",
    twitch: "https://www.twitch.tv/peu_lg",
    instagram: "https://www.instagram.com/peu_lg/",
    categoria: "MELHOR_COACH",
    jogo: "VALORANT",
  },
  {
    name: "palestra",
    org: "Liquid",
    twitter: "https://twitter.com/palestrafps",
    twitch: "",
    instagram: "https://www.instagram.com/palestrafps/",
    categoria: "MELHOR_COACH",
    jogo: "VALORANT",
  },
  {
    name: "Frod",
    org: "MIBR",
    twitter: "https://twitter.com/OfficialfRoD",
    twitch: "https://www.twitch.tv/frod_tv",
    instagram: "https://www.instagram.com/frod.gg",
    categoria: "MELHOR_COACH",
    jogo: "VALORANT",
  },
  {
    name: "Koy",
    org: "FURIA",
    twitter: "https://twitter.com/koyfps",
    twitch: "",
    instagram: "https://www.instagram.com/koyfps/",
    categoria: "MELHOR_COACH",
    jogo: "VALORANT",
  },
  // MELHOR_ORGANIZACAO
  {
    name: "LOUD",
    org: "",
    twitter: "https://twitter.com/LOUDgg",
    twitch: "",
    instagram: "https://www.instagram.com/loudgg",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "VALORANT",
  },
  {
    name: "Liquid",
    org: "",
    twitter: "https://twitter.com/TeamLiquidbr",
    twitch: "",
    instagram: "https://www.instagram.com/teamliquidbr",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "VALORANT",
  },
  {
    name: "FURIA",
    org: "",
    twitter: "https://twitter.com/FURIA",
    twitch: "",
    instagram: "https://www.instagram.com/furiagg/",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "VALORANT",
  },
  {
    name: "The Union",
    org: "",
    twitter: "https://twitter.com/theuniongg",
    twitch: "",
    instagram: "https://www.instagram.com/theuniongg/",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "VALORANT",
    avatar: "the-union",
  },
  // JOGADORA_DO_ANO
  {
    name: "Daiki",
    org: "Liquid",
    twitter: "https://twitter.com/daikifps",
    twitch: "https://www.twitch.tv/daikifpss",
    instagram: "https://www.instagram.com/daikifps/",
    categoria: "JOGADORA_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Bizerra",
    org: "Liquid",
    twitter: "https://twitter.com/bizerravlr",
    twitch: "",
    instagram: "https://www.instagram.com/bizerravlr",
    categoria: "JOGADORA_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Joojina",
    org: "Liquid",
    twitter: "https://twitter.com/joojinavlr",
    twitch: "https://www.twitch.tv/joojina",
    instagram: "https://www.instagram.com/joojinavlr",
    categoria: "JOGADORA_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "bstrdd",
    org: "Liquid",
    twitter: "https://twitter.com/bstrddgg",
    twitch: "https://www.twitch.tv/bstrdd",
    instagram: "https://www.instagram.com/bvstrdd",
    categoria: "JOGADORA_DO_ANO",
    jogo: "VALORANT",
  },
  // JOGADOR_DO_ANO
  {
    name: "Aspas",
    org: "Leviatán",
    twitter: "https://twitter.com/aspaszin",
    twitch: "https://www.twitch.tv/aspaszin",
    instagram: "https://www.instagram.com/aspaszin",
    categoria: "JOGADOR_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Less",
    org: "LOUD",
    twitter: "https://twitter.com/loud_less",
    twitch: "https://www.twitch.tv/lessvlr",
    instagram: "https://www.instagram.com/lessvlr/",
    categoria: "JOGADOR_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Raafa",
    org: "UNIÃO",
    twitter: "https://twitter.com/raafawp",
    twitch: "",
    instagram: "",
    categoria: "JOGADOR_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Saadhak",
    org: "LOUD",
    twitter: "https://twitter.com/loud_saadhak",
    twitch: "https://www.twitch.tv/saadhak",
    instagram: "https://www.instagram.com/saadhak.gg",
    categoria: "JOGADOR_DO_ANO",
    jogo: "VALORANT",
  },
  // PERSONALIDADE_DO_ANO
  {
    name: "Otsuka",
    org: "FURIA",
    twitter: "https://twitter.com/otsukafps",
    twitch: "https://www.twitch.tv/otsukaXD",
    instagram: "https://www.instagram.com/otsukaxd/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Coreano",
    org: "LOUD",
    twitter: "https://twitter.com/Coreano",
    twitch: "http://twitch.tv/coreano",
    instagram: "http://instagram.com/Coreanowo",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "FRTTT",
    org: "Liquid",
    twitter: "https://twitter.com/frtfps",
    twitch: "https://www.twitch.tv/frttt",
    instagram: "https://www.instagram.com/frtttfps/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Paula Nobre",
    org: "FURIA",
    twitter: "https://twitter.com/paulanobrez",
    twitch: "https://www.twitch.tv/paulanobre",
    instagram: "https://www.instagram.com/paulanobrez/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "VALORANT",
    avatar: "paula-nobre",
  },
];
