import React, { useMemo } from "react";
import ReactGA from "react-ga";

import itauLogo from "../assets/itau.png";
import wibrLogo from "../assets/wibr.png";
import gcLogo from "../assets/gamersclub.png";

import { GAME_TAG } from "../constants/game";
import "../css/footer.css";

export function Footer({ game }) {
  const itauImageComponent = useMemo(() => {
    return (
      <figure className="footer-img-content footer-presented-logo">
        <a
          onClick={() => {
            ReactGA.event({
              category: "Action",
              action: "Click",
              label: "Click no logo do Itaú",
            });
          }}
          href="https://www.itau.com.br/?utm_source=awards&utm_medium=lp&utm_campaign=awards"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={itauLogo} alt="itaú" />
        </a>
      </figure>
    );
  }, []);

  const wibrImageComponent = useMemo(() => {
    return (
      <figure className="footer-img-content footer-support-logo">
        <a
          onClick={() => {
            ReactGA.event({
              category: "Action",
              action: "Click",
              label: "Click no logo da Wibr",
            });
          }}
          href="http://wibr.com.br/?utm_source=awards&utm_medium=lp&utm_campaign=awards"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={wibrLogo} alt="Wibr" />
        </a>
      </figure>
    );
  }, []);

  const gcImageComponent = useMemo(() => {
    return (
      <figure className="footer-img-content footer-producer-logo">
        <a
          onClick={() => {
            ReactGA.event({
              category: "Action",
              action: "Click",
              label:
                game === GAME_TAG.CS
                  ? "Click no logo da Gamersclub"
                  : "Click no logo da Gamersclub",
            });
          }}
          href={
            game === GAME_TAG.CS
              ? "https://gamersclub.com.br"
              : "https://gamersclub.gg/"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={gcLogo} alt="Gamersclub" />
        </a>
      </figure>
    );
  }, [game]);

  const sponsorsImagesCSGOComponent = useMemo(() => {
    return (
      <div className="footer-content footer-content-csgo">
        {itauImageComponent}
        {wibrImageComponent}
        <div className="footer-content-separator"></div>
        {gcImageComponent}
      </div>
    );
  }, [itauImageComponent, wibrImageComponent, gcImageComponent]);

  const sponsorsImagesValorantComponent = useMemo(() => {
    return (
      <div className="footer-content footer-content-valorant">
        {itauImageComponent}
        {wibrImageComponent}
        <div className="footer-content-separator"></div>
        {gcImageComponent}
      </div>
    );
  }, [itauImageComponent, wibrImageComponent, gcImageComponent]);

  return (
    <footer className="footer-container">
      {game === GAME_TAG.CS ? sponsorsImagesCSGOComponent : null}
      {game === GAME_TAG.VALORANT ? sponsorsImagesValorantComponent : null}
    </footer>
  );
}
