import React, { useMemo } from "react";

export const ALinkButton = ({ href, children, game }) => {
  const prefixCss = useMemo(
    () =>
      game === "CS" ? "cs" : "valorant",
    [game]
  );

  return (
    <a href={href} target="_blank" rel="noreferrer" className="alink">
      <div className={`${prefixCss}-cta alink`}>{children}</div>
    </a>
  );
};
