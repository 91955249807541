import {
  VOTE_CSGO_ASSAULT,
  VOTE_CSGO_AWP,
  VOTE_CSGO_CAPTAIN,
  VOTE_CSGO_COACH,
  VOTE_CSGO_FEMALE_PLAYER,
  VOTE_CSGO_FOREIGN_PLAYER,
  VOTE_CSGO_INFLUENCER,
  VOTE_CSGO_LEAGUE_ORG,
  VOTE_CSGO_LEAGUE_PLAYER,
  VOTE_CSGO_MALE_PLAYER,
  VOTE_CSGO_NEW_PLAYER,
  VOTE_CSGO_ORG,
  VOTE_CSGO_YOUNG_PLAYER,
} from "../constants/action-types";

// CSGO ACTIONS

export function voteForCsgoAssault(payload) {
  return { type: VOTE_CSGO_ASSAULT, payload };
}

export function voteForCsgoAwp(payload) {
  return { type: VOTE_CSGO_AWP, payload };
}

export function voteForCsgoCaptain(payload) {
  return { type: VOTE_CSGO_CAPTAIN, payload };
}

export function voteForCsgoCoach(payload) {
  return { type: VOTE_CSGO_COACH, payload };
}

export function voteForCsgoOrg(payload) {
  return { type: VOTE_CSGO_ORG, payload };
}

export function voteForCsgoNewPlayer(payload) {
  return { type: VOTE_CSGO_NEW_PLAYER, payload };
}

export function voteForCsgoForeignPlayer(payload) {
  return { type: VOTE_CSGO_FOREIGN_PLAYER, payload };
}

export function voteForCsgoMalePlayer(payload) {
  return { type: VOTE_CSGO_MALE_PLAYER, payload };
}

export function voteForCsgoFemalePlayer(payload) {
  return { type: VOTE_CSGO_FEMALE_PLAYER, payload };
}

export function voteForCsgoInfluencer(payload) {
  return { type: VOTE_CSGO_INFLUENCER, payload };
}

export function voteForCsgoLeaguePlayer(payload) {
  return { type: VOTE_CSGO_LEAGUE_PLAYER, payload };
}

export function voteForCsgoLeagueOrg(payload) {
  return { type: VOTE_CSGO_LEAGUE_ORG, payload };
}

export function voteForCsgoYoungPlayer(payload) {
  return { type: VOTE_CSGO_YOUNG_PLAYER, payload };
}
