import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import ReactGA from "react-ga";
import { publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

import "antd/dist/antd.min.css";

import "./css/app.css";
import "./css/carousel.css";

const history = createHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <React.Fragment>
        <Router history={history}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                component={route.component}
                key={idx}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
