import React, { Component } from "react";
import ChosenCard from "../components/ChosenCard";
import Stepper from "../components/Stepper";
import Error from "../components/Error";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";

import {
  CSGO_TOTAL_CATEGORIES,
  categories as categoriesCSGO,
} from "../constants/csgo/categories";
import {
  VALORANT_TOTAL_CATEGORIES,
  categories as categoriesValorant,
} from "../constants/valorant/categories";

import {
  voteForCsgoAssault,
  voteForCsgoAwp,
  voteForCsgoCaptain,
  voteForCsgoCoach,
  voteForCsgoOrg,
  voteForCsgoForeignPlayer,
  voteForCsgoMalePlayer,
  voteForCsgoFemalePlayer,
  voteForCsgoNewPlayer,
  voteForCsgoInfluencer,
  voteForCsgoLeaguePlayer,
  voteForCsgoLeagueOrg,
  voteForCsgoYoungPlayer,
  voteForValorantInitiator,
  voteForValorantController,
  voteForValorantDuelist,
  voteForValorantSentinel,
  voteForValorantCaptain,
  voteForValorantCoach,
  voteForValorantOrg,
  voteForValorantFemalePlayer,
  voteForValorantMalePlayer,
  voteForValorantInfluencer,
  choose,
  selectGame,
  confirmedVotesForCS,
  confirmedVotesForValorant,
  setErrorMessage,
} from "../actions/index";
import { GC_AWARDS_API } from "../constants/api";

const mapStateToProps = (state) => {
  return {
    chosen: state.app.chosen,
    hasVotedForValorant: state.app.hasVotedForValorant,
    hasVotedForCS: state.app.hasVotedForCS,
    assault: state.csgo.assault,
    awp: state.csgo.awp,
    csGoCaptain: state.csgo.captain,
    csGoCoach: state.csgo.coach,
    csGoOrganization: state.csgo.organization,
    newPlayer: state.csgo.newPlayer,
    foreignPlayer: state.csgo.foreignPlayer,
    csGoMalePlayer: state.csgo.malePlayer,
    csGoFemalePlayer: state.csgo.femalePlayer,
    csGoInfluencer: state.csgo.influencer,
    csGoLeaguePlayer: state.csgo.leaguePlayer,
    csGoLeagueOrg: state.csgo.leagueOrg,
    csGoYoungPlayer: state.csgo.youngPlayer,
    initiator: state.valorant.initiator,
    controller: state.valorant.controller,
    duelist: state.valorant.duelist,
    sentinel: state.valorant.sentinel,
    valorantCaptain: state.valorant.captain,
    valorantCoach: state.valorant.coach,
    valorantOrganization: state.valorant.organization,
    valorantFemalePlayer: state.valorant.femalePlayer,
    valorantMalePlayer: state.valorant.malePlayer,
    valorantInfluencer: state.valorant.influencer,
    errorMessage: state.app.errorMessage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    voteForCsgoAssault: (assault) => dispatch(voteForCsgoAssault(assault)),
    voteForCsgoAwp: (awp) => dispatch(voteForCsgoAwp(awp)),
    voteForCsgoCaptain: (captain) => dispatch(voteForCsgoCaptain(captain)),
    voteForCsgoCoach: (coach) => dispatch(voteForCsgoCoach(coach)),
    voteForCsgoOrg: (org) => dispatch(voteForCsgoOrg(org)),
    voteForCsgoNewPlayer: (newPlayer) =>
      dispatch(voteForCsgoNewPlayer(newPlayer)),
    voteForCsgoForeignPlayer: (foreignPlayer) =>
      dispatch(voteForCsgoForeignPlayer(foreignPlayer)),
    voteForCsgoMalePlayer: (malePlayer) =>
      dispatch(voteForCsgoMalePlayer(malePlayer)),
    voteForCsgoFemalePlayer: (femalePlayer) =>
      dispatch(voteForCsgoFemalePlayer(femalePlayer)),
    voteForCsgoInfluencer: (influencer) =>
      dispatch(voteForCsgoInfluencer(influencer)),
    voteForCsgoLeaguePlayer: (leaguePlayer) =>
      dispatch(voteForCsgoLeaguePlayer(leaguePlayer)),
    voteForCsgoLeagueOrg: (leagueOrg) =>
      dispatch(voteForCsgoLeagueOrg(leagueOrg)),
    voteForCsgoYoungPlayer: (youngPlayer) =>
      dispatch(voteForCsgoYoungPlayer(youngPlayer)),
    voteForValorantInitiator: (initiator) =>
      dispatch(voteForValorantInitiator(initiator)),
    voteForValorantController: (controller) =>
      dispatch(voteForValorantController(controller)),
    voteForValorantDuelist: (duelist) =>
      dispatch(voteForValorantDuelist(duelist)),
    voteForValorantSentinel: (sentinel) =>
      dispatch(voteForValorantSentinel(sentinel)),
    voteForValorantCaptain: (captain) =>
      dispatch(voteForValorantCaptain(captain)),
    voteForValorantCoach: (coach) => dispatch(voteForValorantCoach(coach)),
    voteForValorantOrg: (organization) =>
      dispatch(voteForValorantOrg(organization)),
    voteForValorantFemalePlayer: (femalePlayer) =>
      dispatch(voteForValorantFemalePlayer(femalePlayer)),
    voteForValorantMalePlayer: (malePlayer) =>
      dispatch(voteForValorantMalePlayer(malePlayer)),
    voteForValorantInfluencer: (influencer) =>
      dispatch(voteForValorantInfluencer(influencer)),
    selectGame: (game) => dispatch(selectGame(game)),
    confirmedVotesForCS: (hasVotedForCS) =>
      dispatch(confirmedVotesForCS(hasVotedForCS)),
    confirmedVotesForValorant: (hasVotedForValorant) =>
      dispatch(confirmedVotesForValorant(hasVotedForValorant)),
    setErrorMessage: (errorMessage) => dispatch(setErrorMessage(errorMessage)),
    choose: (chosen) => dispatch(choose(chosen)),
  };
}

class ConnectedChoicesReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories:
        props.game === "CS"
          ? categoriesCSGO
          : props.game === "Valorant"
          ? categoriesValorant
          : {},
    };
  }

  handleVotes() {
    if (this.props.game === "CS") {
      ReactGA.event({
        category: "Action",
        action: "Click",
        label: "Clicou em CONFIRMAR VOTOS na página de Counter-strike",
      });
      const cookies = new Cookies();
      if (cookies.get("gcmg:refreshToken")) {
        const authToken = cookies.get("gcmg:refreshToken");
        fetch(`${GC_AWARDS_API}/csgo`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assault: this.props.assault,
            awp: this.props.awp,
            captain: this.props.csGoCaptain,
            coach: this.props.csGoCoach,
            organization: this.props.csGoOrganization,
            newPlayer: this.props.newPlayer,
            foreignPlayer: this.props.foreignPlayer,
            malePlayer: this.props.csGoMalePlayer,
            femalePlayer: this.props.csGoFemalePlayer,
            influencer: this.props.csGoInfluencer,
            leaguePlayer: this.props.csGoLeaguePlayer,
            leagueOrg: this.props.csGoLeagueOrg,
            youngPlayer: this.props.csGoYoungPlayer,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              this.props.setErrorMessage(data.message);
            } else {
              this.props.confirmedVotesForCS(true);
            }
          })
          .catch((error) => {
            this.props.setErrorMessage(error.message);
          });
      }
    }

    if (this.props.game === "Valorant") {
      ReactGA.event({
        category: "Action",
        action: "Click",
        label: "Clicou em CONFIRMAR VOTOS na página de VALORANT",
      });
      const cookies = new Cookies();
      if (cookies.get("gcmg:refreshToken")) {
        const authToken = cookies.get("gcmg:refreshToken");
        fetch(`${GC_AWARDS_API}/valorant`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            initiator: this.props.initiator,
            controller: this.props.controller,
            duelist: this.props.duelist,
            sentinel: this.props.sentinel,
            captain: this.props.valorantCaptain,
            coach: this.props.valorantCoach,
            organization: this.props.valorantOrganization,
            femalePlayer: this.props.valorantFemalePlayer,
            malePlayer: this.props.valorantMalePlayer,
            influencer: this.props.valorantInfluencer,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              this.props.setErrorMessage(data.message);
            } else {
              this.props.confirmedVotesForValorant(true);
            }
          })
          .catch((error) => {
            this.props.setErrorMessage(error.message);
          });
      }
    }
  }

  buildCandidateCardsCS() {
    let { game, candidates } = this.props;
    const candidatesCards = [];

    for (let category in candidates) {
      if (category !== "Revisão") {
        candidates[category].forEach((candidate) => {
          switch (category) {
            case categoriesCSGO.MELHOR_ASSAULT:
              if (this.props.assault === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.MELHOR_AWP:
              if (this.props.awp === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.MELHOR_CAPITAO:
              if (this.props.csGoCaptain === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.MELHOR_COACH:
              if (this.props.csGoCoach === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.MELHOR_ORGANIZACAO:
              if (this.props.csGoOrganization === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.REVELACAO:
              if (this.props.newPlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.ESTRANGEIRO_DO_ANO:
              if (this.props.foreignPlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.JOGADORA_DO_ANO:
              if (this.props.csGoFemalePlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.JOGADOR_DO_ANO:
              if (this.props.csGoMalePlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.PERSONALIDADE_DO_ANO:
              if (this.props.csGoInfluencer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.JOGADOR_LIGAS:
              if (this.props.csGoLeaguePlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.ORGANIZACAO_LIGAS:
              if (this.props.csGoLeagueOrg === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesCSGO.JOVEM_DO_ANO:
              if (this.props.csGoYoungPlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            default:
              return null;
          }
        });
      }
    }
    return candidatesCards;
  }

  buildCandidateCardsValorant() {
    let { game, candidates } = this.props;

    const candidatesCards = [];

    for (let category in candidates) {
      if (category !== "Revisão") {
        candidates[category].forEach((candidate) => {
          switch (category) {
            case categoriesValorant.MELHOR_INICIADOR:
              if (this.props.initiator === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.MELHOR_CONTROLADOR:
              if (this.props.controller === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.MELHOR_DUELISTA:
              if (this.props.duelist === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.MELHOR_SENTINELA:
              if (this.props.sentinel === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.MELHOR_CAPITAO:
              if (this.props.valorantCaptain === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.MELHOR_COACH:
              if (this.props.valorantCoach === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.MELHOR_ORGANIZACAO:
              if (this.props.valorantOrganization === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.JOGADORA_DO_ANO:
              if (this.props.valorantFemalePlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.JOGADOR_DO_ANO:
              if (this.props.valorantMalePlayer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            case categoriesValorant.PERSONALIDADE_DO_ANO:
              if (this.props.valorantInfluencer === candidate.name) {
                candidatesCards.push(
                  <ChosenCard
                    key={category}
                    category={category}
                    organization={candidate.org}
                    name={candidate.name}
                    avatar={candidate.avatar}
                    game={game}
                  />
                );
              }
              break;
            default:
              return null;
          }
        });
      }
    }

    return candidatesCards;
  }

  buildCandidateCards() {
    const { game } = this.props;
    if (game === "CS") {
      return this.buildCandidateCardsCS();
    }

    if (game === "Valorant") {
      return this.buildCandidateCardsValorant();
    }
    return [];
  }

  render() {
    let { game } = this.props;
    let candidatesCards = this.buildCandidateCards();

    if (this.props.errorMessage) {
      return <Error game={this.props.game} />;
    }

    if (game === "CS") {
      if (!this.props.hasVotedForCS) {
        return (
          <section className="pd-50 voting-container cs-voting" id="voting">
            <div className="section-title">
              <h1>A decisão agora é sua!</h1>
            </div>
            <div>
              <hr className="cs-hr" />
            </div>
            <div className="section-description cs-section-description">
              <p>
                Essa é a última etapa! É sua última chance de alterar seus
                votos. Quando estiver tudo pronto, clique em{" "}
                <strong>CONFIRMAR TODOS OS VOTOS</strong>.
              </p>
            </div>
            <div className="container">
              <div className="voting">
                <div className="candidates">
                  <div className="award-category cs-category">
                    <svg
                      width="54"
                      height="55"
                      viewBox="0 0 54 55"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.2339 0.612793L34.3125 4.30567V18.25H46.7819L53.1268 25.5467L49.454 49.4995L43.2035 54.8125H0.1875V23.125H13.2284L23.2339 0.612793ZM12.375 28H5.0625V49.9375H12.375V28ZM17.25 49.9375H41.4115L44.911 46.963L47.9657 27.0408L44.5606 23.125H29.4375V7.81937L25.8911 6.63725L17.25 26.0798V49.9375Z"
                        fill="currentColor"
                      />
                    </svg>
                    <h2>Resumo dos votos</h2>
                  </div>
                  <div>
                    <div className="category-candidates">
                      {candidatesCards}
                      <div className="btn-area">
                        <button
                          onClick={() => this.handleVotes()}
                          className="cta-button cs-cta mg-top-30"
                          type="button"
                        >
                          Confirmar todos os votos
                        </button>
                      </div>
                    </div>
                    <div className="steps-wrapper">
                      <Stepper
                        votingMoment={["Revisão"]}
                        steps={this.props.steps}
                        momentIndex={CSGO_TOTAL_CATEGORIES}
                        game="CS"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else {
        return (
          <section className="pd-50 voting-container cs-voting" id="voting">
            <div className="section-title">
              <h1>GG! Agradecemos o seu voto</h1>
            </div>
            <div>
              <hr className="cs-hr" />
            </div>
            <div className="container">
              <div className="voting">
                <div className="candidates">
                  <div className="award-category cs-category">
                    <svg
                      width="54"
                      height="55"
                      viewBox="0 0 54 55"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.2339 0.612793L34.3125 4.30567V18.25H46.7819L53.1268 25.5467L49.454 49.4995L43.2035 54.8125H0.1875V23.125H13.2284L23.2339 0.612793ZM12.375 28H5.0625V49.9375H12.375V28ZM17.25 49.9375H41.4115L44.911 46.963L47.9657 27.0408L44.5606 23.125H29.4375V7.81937L25.8911 6.63725L17.25 26.0798V49.9375Z"
                        fill="currentColor"
                      />
                    </svg>
                    <h2>Resumo dos votos</h2>
                  </div>
                  <div>
                    <div className="category-candidates">
                      {candidatesCards}
                      <div className="btn-area">
                        <button
                          className="cta-button inactive-button mg-top-30 voted-btn"
                          type="button"
                        >
                          Agradecemos seu voto
                        </button>
                      </div>
                    </div>
                    <div className="steps-wrapper">
                      <Stepper
                        votingMoment={["Revisão"]}
                        steps={this.props.steps}
                        momentIndex={CSGO_TOTAL_CATEGORIES}
                        game="CS"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }
    }

    if (game === "Valorant") {
      if (!this.props.hasVotedForValorant) {
        return (
          <section
            className="pd-50 voting-container valorant-voting"
            id="voting"
          >
            <div className="section-title">
              <h1>A decisão agora é sua!</h1>
            </div>
            <div>
              <hr className="valorant-hr" />
            </div>
            <div className="section-description valorant-section-description">
              <p>
                Essa é a última etapa! É sua última chance de alterar seus
                votos. Quando estiver tudo pronto, clique em{" "}
                <strong>CONFIRMAR TODOS OS VOTOS</strong>.
              </p>
            </div>
            <div className="container">
              <div className="voting">
                <div className="candidates">
                  <div className="award-category valorant-category">
                    <svg
                      width="54"
                      height="55"
                      viewBox="0 0 54 55"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.2339 0.612793L34.3125 4.30567V18.25H46.7819L53.1268 25.5467L49.454 49.4995L43.2035 54.8125H0.1875V23.125H13.2284L23.2339 0.612793ZM12.375 28H5.0625V49.9375H12.375V28ZM17.25 49.9375H41.4115L44.911 46.963L47.9657 27.0408L44.5606 23.125H29.4375V7.81937L25.8911 6.63725L17.25 26.0798V49.9375Z"
                        fill="currentColor"
                      />
                    </svg>
                    <h2>Resumo dos votos</h2>
                  </div>
                  <div>
                    <div className="category-candidates">
                      {candidatesCards}
                      <div className="btn-area">
                        <button
                          onClick={() => this.handleVotes()}
                          className="cta-button valorant-cta mg-top-30"
                          type="button"
                        >
                          Confirmar todos os votos
                        </button>
                      </div>
                    </div>
                    <div className="steps-wrapper">
                      <Stepper
                        votingMoment={["Revisão"]}
                        steps={this.props.steps}
                        momentIndex={VALORANT_TOTAL_CATEGORIES}
                        game="Valorant"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else {
        return (
          <section
            className="pd-50 voting-container valorant-voting"
            id="voting"
          >
            <div className="section-title">
              <h1>GG! Agradecemos o seu voto</h1>
            </div>
            <div>
              <hr className="valorant-hr" />
            </div>
            <div className="container">
              <div className="voting">
                <div className="candidates">
                  <div className="award-category valorant-category">
                    <svg
                      width="54"
                      height="55"
                      viewBox="0 0 54 55"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.2339 0.612793L34.3125 4.30567V18.25H46.7819L53.1268 25.5467L49.454 49.4995L43.2035 54.8125H0.1875V23.125H13.2284L23.2339 0.612793ZM12.375 28H5.0625V49.9375H12.375V28ZM17.25 49.9375H41.4115L44.911 46.963L47.9657 27.0408L44.5606 23.125H29.4375V7.81937L25.8911 6.63725L17.25 26.0798V49.9375Z"
                        fill="currentColor"
                      />
                    </svg>
                    <h2>Resumo dos votos</h2>
                  </div>
                  <div>
                    <div className="category-candidates">
                      {candidatesCards}
                      <div className="btn-area">
                        <button
                          className="cta-button inactive-button mg-top-30 voted-btn"
                          type="button"
                        >
                          Agradecemos seu voto
                        </button>
                      </div>
                    </div>
                    <div className="steps-wrapper">
                      <Stepper
                        votingMoment={["Revisão"]}
                        steps={this.props.steps}
                        momentIndex={VALORANT_TOTAL_CATEGORIES}
                        game="Valorant"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }
    }
  }
}

const ChoicesReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedChoicesReview);

export default ChoicesReview;
