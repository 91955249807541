import {
  VOTE_VALORANT_CAPTAIN,
  VOTE_VALORANT_COACH,
  VOTE_VALORANT_CONTROLLER,
  VOTE_VALORANT_DUELIST,
  VOTE_VALORANT_FEMALE_PLAYER,
  VOTE_VALORANT_INFLUENCER,
  VOTE_VALORANT_INITIATOR,
  VOTE_VALORANT_MALE_PLAYER,
  VOTE_VALORANT_ORG,
  VOTE_VALORANT_SENTINEL,
} from "../constants/action-types";

export function voteForValorantInitiator(payload) {
  return { type: VOTE_VALORANT_INITIATOR, payload };
}

export function voteForValorantController(payload) {
  return { type: VOTE_VALORANT_CONTROLLER, payload };
}

export function voteForValorantDuelist(payload) {
  return { type: VOTE_VALORANT_DUELIST, payload };
}

export function voteForValorantSentinel(payload) {
  return { type: VOTE_VALORANT_SENTINEL, payload };
}

export function voteForValorantCaptain(payload) {
  return { type: VOTE_VALORANT_CAPTAIN, payload };
}

export function voteForValorantCoach(payload) {
  return { type: VOTE_VALORANT_COACH, payload };
}

export function voteForValorantOrg(payload) {
  return { type: VOTE_VALORANT_ORG, payload };
}

export function voteForValorantMalePlayer(payload) {
  return { type: VOTE_VALORANT_MALE_PLAYER, payload };
}

export function voteForValorantFemalePlayer(payload) {
  return { type: VOTE_VALORANT_FEMALE_PLAYER, payload };
}

export function voteForValorantInfluencer(payload) {
  return { type: VOTE_VALORANT_INFLUENCER, payload };
}
