import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import itauLogo from "../assets/itau.png";
import logoValorant from "../assets/valorant-gcawards-logo.png";
import logoCS from "../assets/csgo-gcawardslogo.png";

import { selectGame } from "../actions/index";

function mapDispatchToProps(dispatch) {
  return {
    selectGame: (game) => dispatch(selectGame(game)),
  };
}

const pagina = {
  jogo: {
    CS: {
      activeClass: "cs-hero",
      image: logoCS,
      title: "A maior premiação do Counter-strike brasileiro",
      description:
        "Chegou a hora de prestigiar e votar naqueles que fizeram a diferença no cenário BR de Counter-strike em 2023.",
    },
    Valorant: {
      activeClass: "valorant-hero",
      image: logoValorant,
      title: "A maior premiação do VALORANT brasileiro",
      description:
        "Chegou a hora de prestigiar e votar naqueles que fizeram a diferença no cenário BR de VALORANT em 2023.",
    },
  },
  rotas: [
    {
      name: "Valorant",
      link: "/valorant",
      content: (
        <div className="game-link game-link-valorant">
          <div className="icons">
            <img
              className="unfocused-logo"
              src={logoValorant}
              alt="Gamers Club Awards 2023"
            />
          </div>
        </div>
      ),
    },
    {
      name: "CS",
      link: "/csgo",
      content: (
        <div className="game-link game-link-cs">
          <div className="icons">
            <img
              className="unfocused-logo"
              src={logoCS}
              alt="Gamers Club Awards 2023"
            />
          </div>
        </div>
      ),
    },
  ],
};

class ConnectedGameSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagina: pagina.jogo[props.game],
      rotas: pagina.rotas.filter((rota) => rota.name !== props.game),
    };
  }

  render() {
    return (
      <section className="game-switch">
        <div className={`active-game-option ${this.state.pagina.activeClass}`}>
          <div>
            <div className="main-logo">
              <img
                className="main-logo-img"
                src={this.state.pagina.image}
                alt="Gamers Club Awards 2023"
              />
              <img
                className="main-logo-img main-logo-sponsor"
                src={itauLogo}
                alt="itaú"
              />
            </div>
            <div className="active-game-title">
              <h1>{this.state.pagina.title}</h1>
            </div>
            <div className="col-80-p">
              <p>{this.state.pagina.description}</p>
            </div>
          </div>
        </div>
        {this.state.rotas.map((item, index) => (
          <Link
            className={`inactive-game-option ${item.name.toLowerCase()}-inactive`}
            key={`${index}-${item.name}`}
            to={item.link}
          >
            {item.content}
          </Link>
        ))}
      </section>
    );
  }
}

const GameSwitch = connect(null, mapDispatchToProps)(ConnectedGameSwitch);

export default GameSwitch;
