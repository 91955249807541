import { Route } from "react-router-dom";

const AppRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return <Component {...props} />;
        }}
    />
);

export default AppRoute;