import React, { Component } from "react";
import JudgeCard from "../components/JudgeCard";
import ReactGA from "react-ga";
import { judges as judgesCsGo } from "../constants/csgo/judges";
import { judges as judgesValorant } from "../constants/valorant/judges";

class Judges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrow: "up",
    };
  }

  render() {
    let { game } = this.props;
    let judgesCards = [];
    let judges = [];

    if (game === "CS") {
      judges = judgesCsGo;
    }

    if (game === "Valorant") {
      judges = judgesValorant;
    }

    judges.forEach((judge) => {
      judgesCards.push(
        <JudgeCard
          name={judge.name}
          org={judge.org}
          twitch={judge.twitch}
          twitter={judge.twitter}
          instagram={judge.instagram}
          avatar={judge.avatar}
          key={Math.random().toString(36).substring(2)}
        />
      );
    });

    const cssPrefix =
      game === "CS" ? "cs" : "valorant";

    const gameName = game === "CS" ? "Counter-strike" : "Valorant";

    return (
      <section className="pd-50">
        <div>
          <div className="section-title">
            <h1 className="judges-title">Quem escolheu os indicados?</h1>
            {this.state.arrow === "up" ? (
              <>
                <div
                  onClick={() => this.setState({ arrow: "down" })}
                  className={`arrow-${this.state.arrow} ${cssPrefix}-arrow`}
                ></div>
                <div>
                  <hr className={`${cssPrefix}-hr`} style={{ width: "100%" }} />
                </div>
                <div className="margin-auto">
                  <button
                    onClick={() => {
                      this.setState({ arrow: "down" });
                      ReactGA.event({
                        category: "Action",
                        action: "Click",
                        label: `Clicou no botão EXIBIR JURADOS na página do ${gameName}`,
                      });
                    }}
                    className={`${cssPrefix}-cta cta-button`}
                    type="button"
                  >
                    Veja quem escolheu
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={() => this.setState({ arrow: "up" })}
                  className={`arrow-${this.state.arrow} ${cssPrefix}-arrow`}
                ></div>
                <div>
                  <hr className={`${cssPrefix}-hr`} />
                </div>
                <div className="margin-auto">
                  <button
                    onClick={() => {
                      this.setState({ arrow: "up" });
                      ReactGA.event({
                        category: "Action",
                        action: "Click",
                        label: `Clicou no botão OCULTAR JURADOS na página do ${gameName}`,
                      });
                    }}
                    className={`${cssPrefix}-cta cta-button`}
                    type="button"
                  >
                    Ocultar
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="container">
            {this.state.arrow === "down" && <>{judgesCards}</>}
          </div>
        </div>
      </section>
    );
  }
}

export default Judges;
