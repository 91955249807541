import React, { Component } from "react";
import Countdown from "react-countdown";
import ReactGA from "react-ga";
import { endVotingDate } from "../constants/endVotingDate";
import { GAME_TAG } from "../constants/game";

class PremiereEvent extends Component {
  render() {
    let { game, hasVoted } = this.props;

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return <span id="countdown">VOTAÇÃO ENCERRADA!</span>;
      } else if (hasVoted) {
        return <span id="countdown">VOCÊ JÁ VOTOU!</span>;
      } else {
        return (
          <>
            <h3>Tempo restante para você votar:</h3>
            <span id="countdown">
              {days}d:{hours}h:{minutes}m:{seconds}s
            </span>
          </>
        );
      }
    };

    if (game === "CS") {
      return (
        <section className="event-premiere cs-footer">
          <article className="event-info-title">
            <h3>
              Descubra os vencedores no canal da <br />
              Gamers Club CS <br />
              no dia 20 de Janeiro de 2024, às 19h!
            </h3>
          </article>
          <div className="event-info">
            <div className="countdown-cta">
              <div className="countdown-button-area">
                <a
                  onClick={() => {
                    ReactGA.event({
                      category: "Action",
                      action: "Click",
                      label: `Acessou Twitch da Gamers Club CS`,
                    });
                  }}
                  href="https://www.twitch.tv/gamersclubcs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="countdown-button cta-button" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M391.17,103.47H352.54v109.7h38.63ZM285,103H246.37V212.75H285ZM120.83,0,24.31,91.42V420.58H140.14V512l96.53-91.42h77.25L487.69,256V0ZM449.07,237.75l-77.22,73.12H294.61l-67.6,64v-64H140.14V36.58H449.07Z"
                      ></path>
                    </svg>
                    Siga nosso canal
                  </button>
                </a>
              </div>
              {
                <div className="countdown">
                  <Countdown
                    date={endVotingDate.get(GAME_TAG.CS)}
                    intervalDelay={1000}
                    renderer={renderer}
                  />
                </div>
              }
            </div>
          </div>
        </section>
      );
    }

    if (game === "Valorant") {
      return (
        <section className="event-premiere valorant-footer">
          <article className="event-info-title">
            <h3>
              Descubra os vencedores no canal da <br />
              Gamers Club VALORANT <br />
              no dia 21 de Janeiro de 2024, às 19h!
            </h3>
          </article>
          <div className="event-info">
            <div className="countdown-cta">
              <div className="countdown-button-area">
                <a
                  onClick={() => {
                    ReactGA.event({
                      category: "Action",
                      action: "Click",
                      label: `Acessou Twitch da Gamers Club Valorant`,
                    });
                  }}
                  href="https://www.twitch.tv/gamersclubvalorant"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="countdown-button cta-button" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M391.17,103.47H352.54v109.7h38.63ZM285,103H246.37V212.75H285ZM120.83,0,24.31,91.42V420.58H140.14V512l96.53-91.42h77.25L487.69,256V0ZM449.07,237.75l-77.22,73.12H294.61l-67.6,64v-64H140.14V36.58H449.07Z"
                      ></path>
                    </svg>
                    Siga nosso canal
                  </button>
                </a>
              </div>
              {
                <div className="countdown">
                  <Countdown
                    date={endVotingDate.get(GAME_TAG.VALORANT)}
                    intervalDelay={1000}
                    renderer={renderer}
                  />
                </div>
              }
            </div>
          </div>
        </section>
      );
    }
  }
}

export default PremiereEvent;
