export const importAllAssets = (files, name, avatar) => {
  let images = {};
  let avatarUrl = "";

  files.keys().map((file, _index) => {
    images[file.replace("./", "")] = files(file);
    const sanitizeUrlItem = file
      .replace("./", "")
      .replace(/\.(png|jpe?g|svg)$/, "")
      .toLowerCase();

    if (
      (avatar && sanitizeUrlItem === avatar.toLowerCase()) ||
      sanitizeUrlItem === name.toLowerCase()
    ) {
      avatarUrl = files(file);
    }

    return avatarUrl;
  });

  return avatarUrl;
};
