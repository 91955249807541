import React, { Component } from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    errorMessage: state.app.errorMessage,
  };
};

class ConnectedError extends Component {
  render() {
    if (this.props.game === "CS") {
      return (
        <section className="pd-50 cs-voting" id="voting">
          <div>
            <div className="section-title">
              <h1>Ocorreu um erro</h1>
            </div>
            <div>
              <hr className="cs-hr" />
            </div>
            <div className="section-description cs-section-description">
              <p>{this.props.errorMessage}</p>
            </div>
          </div>
        </section>
      );
    }
    if (this.props.game === "Valorant") {
      return (
        <section className="pd-50 valorant-voting" id="voting">
          <div>
            <div className="section-title">
              <h1>Ocorreu um erro</h1>
            </div>
            <div>
              <hr className="valorant-hr" />
            </div>
            <div className="section-description valorant-section-description">
              <p>{this.props.errorMessage}</p>
            </div>
          </div>
        </section>
      );
    }
  }
}

const Error = connect(mapStateToProps, null)(ConnectedError);

export default Error;
