export const judges = [
  {
    name: "Celo",
    org: "Caster",
    twitter: "https://twitter.com/fpscelo",
    twitch: "https://www.twitch.tv/fpscelo",
    instagram: "https://www.instagram.com/fpscelo/",
  },
  {
    name: "ch4k3nio",
    org: "Gamers Club",
    twitter: "https://twitter.com/MartinBiolchi",
    twitch: "",
    instagram: "https://www.instagram.com/biolchimartin/",
  },
  {
    name: "gio",
    org: "Caster",
    twitter: "https://twitter.com/gio_fps",
    twitch: "https://www.twitch.tv/gio_fps",
    instagram: "https://www.instagram.com/gio.deniz/",
  },
  {
    name: "Spricigo",
    org: "DRAFT5",
    twitter: "https://twitter.com/LucasSpricigo",
    twitch: "",
    instagram: "https://www.instagram.com/lucasspricigo/",
  },
  {
    name: "Tge",
    org: "9z",
    twitter: "https://twitter.com/tgefps",
    twitch: "https://www.twitch.tv/tgefps",
    instagram: "https://www.instagram.com/tge_motta",
  },
  {
    name: "pino",
    org: "BESTIA",
    twitter: "https://twitter.com/pino_csgo",
    twitch: "",
    instagram: "https://www.instagram.com/pinocsgo/",
  },
  {
    name: "sidde",
    org: "FURIA",
    twitter: "https://twitter.com/siddecs",
    twitch: "https://www.twitch.tv/siddecs",
    instagram: "https://www.instagram.com/siddecs/",
  },
  {
    name: 'Rafael "Rafinha" Ferreira',
    org: "Blix",
    twitter: "https://twitter.com/Rafinhafps",
    twitch: "https://www.twitch.tv/rafinhaaa",
    instagram: "https://www.instagram.com/rafaelmf17/",
    avatar: "rafinha",
  },
  {
    name: "Xamp",
    org: "Coach",
    twitter: "https://twitter.com/xampCS",
    twitch: "https://www.twitch.tv/xampcs",
    instagram: "https://www.instagram.com/xampcs/",
  },
  {
    name: 'Gabriel "pumba" Melo',
    org: "Dust 2",
    twitter: "https://twitter.com/gabrielpumba",
    twitch: "",
    instagram: "https://www.instagram.com/gabriel.pumba/",
    avatar: "pumba",
  },
  {
    name: "Lucas Yuji / YJ*",
    org: "Caster",
    twitter: "https://twitter.com/yujicsgo",
    twitch: "https://www.twitch.tv/yuji_fps",
    instagram: "https://www.instagram.com/lucasyujis/",
    avatar: "yuji",
  },
  {
    name: "VIICK",
    org: "Caster",
    twitter: "https://twitter.com/viickmicheleto",
    twitch: "https://www.twitch.tv/viickmicheleto",
    instagram: "https://www.instagram.com/viickmicheleto/",
  },
  {
    name: "Cobaia",
    org: "Caster",
    twitter: "https://twitter.com/BrunoCobaia_",
    twitch: "https://www.twitch.tv/brunocobaia",
    instagram: "https://www.instagram.com/brunocobaia/",
  },
  {
    name: 'Camila "cAmyy" Natale',
    org: "W7M",
    twitter: "https://twitter.com/camyyfps",
    twitch: "https://www.twitch.tv/camyyfps",
    instagram: "https://www.instagram.com/camyyfps/",
    avatar: "camyy",
  },
  {
    name: "Raul Ribeiro/RauleS",
    org: "CBCS",
    twitter: "https://twitter.com/RauleSrib",
    twitch: "https://www.twitch.tv/raulezs",
    instagram: "https://www.instagram.com/raulesrib/",
    avatar: "raules",
  },
  {
    name: "Victor Hugo Porto",
    org: "Dust 2",
    twitter: "https://twitter.com/vhporto",
    twitch: "",
    instagram: "https://www.instagram.com/portovictorh/",
    avatar: "vh-porto",
  },
  {
    name: 'Jairo "Foxer"',
    org: "Game Arena",
    twitter: "https://twitter.com/Foxer_JJ",
    twitch: "https://www.twitch.tv/jairofoxer",
    instagram: "https://www.instagram.com/foxer_jj/",
    avatar: "foxer",
  },
  {
    name: "Fly",
    org: "GC / MIBR",
    twitter: "https://twitter.com/yurifly",
    twitch: "https://www.twitch.tv/gamersclub_fly",
    instagram: "https://www.instagram.com/yuri_fly/",
  },
  {
    name: "AMD",
    org: "Caster / Influencer",
    twitter: "https://twitter.com/AMD22k",
    twitch: "https://www.twitch.tv/amd22k",
    instagram: "https://www.instagram.com/amd22k/",
  },
  {
    name: "Julia Garcia",
    org: "Jornalista",
    twitter: "https://twitter.com/garciajulia",
    twitch: "",
    instagram: "https://www.instagram.com/julianunesgarcia/",
    avatar: "julia-garcia",
  },
  {
    name: "Nessy",
    org: "Caster",
    twitter: "https://twitter.com/nessyteras",
    twitch: "https://www.twitch.tv/nessyteras",
    instagram: "https://www.instagram.com/nessyteras/",
  },
  {
    name: "Craw",
    org: "Caster",
    twitter: "https://twitter.com/eusouocraw",
    twitch: "https://www.twitch.tv/crawzao",
    instagram: "https://www.instagram.com/eusouocraw/",
  },
  {
    name: "João Righi",
    org: "RED Canids",
    twitter: "https://twitter.com/righifps",
    twitch: "https://www.twitch.tv/righifps",
    instagram: "https://www.instagram.com/righifps/",
    avatar: "joao-righi",
  },
  {
    name: "Beatriste",
    org: "Caster",
    twitter: "https://twitter.com/beatristefps",
    twitch: "https://www.twitch.tv/xBeatriste",
    instagram: "https://www.instagram.com/beatristefps/",
  },
  {
    name: "Ferrantini",
    org: "Caster",
    twitter: "https://twitter.com/MHFerrantini",
    twitch: "",
    instagram: "https://www.instagram.com/MarceloFerrantini/",
  },
  {
    name: "Naper",
    org: "Caster",
    twitter: "https://twitter.com/napeRx",
    twitch: "https://www.twitch.tv/naperx_",
    instagram: "https://www.instagram.com/naperx",
  },
  {
    name: "Kakarota",
    org: "Caster",
    twitter: "https://twitter.com/aKakarota",
    twitch: "https://www.twitch.tv/akakarota",
    instagram: "https://www.instagram.com/akakarota/",
  },
  {
    name: "Reeh",
    org: "Caster",
    twitter: "https://twitter.com/reehplayss",
    twitch: "https://www.twitch.tv/reeh",
    instagram: "https://www.instagram.com/reehplays/",
  },
  {
    name: "Qep",
    org: "Caster",
    twitter: "https://twitter.com/qepfps",
    twitch: "https://www.twitch.tv/qepfps",
    instagram: "https://www.instagram.com/qepfps/",
  },
  {
    name: "Pirulita",
    org: "Caster",
    twitter: "https://twitter.com/ggpirulita",
    twitch: "https://www.twitch.tv/ggpirulita",
    instagram: "https://www.instagram.com/ggpirulita/",
  },
  {
    name: "Aidualc",
    org: "Caster",
    twitter: "https://twitter.com/Aidualc_fps",
    twitch: "https://www.twitch.tv/aidualc",
    instagram: "https://www.instagram.com/aidualc_fps/",
  },
  {
    name: "Raixt84",
    org: "GaulesTV",
    twitter: "https://twitter.com/raixt84",
    twitch: "https://www.twitch.tv/raixt84",
    instagram: "https://www.instagram.com/raixt84/",
  },
  {
    name: "Napa",
    org: "GaulesTV",
    twitter: "https://twitter.com/napaz1ka",
    twitch: "https://www.twitch.tv/napaz1ka",
    instagram: "https://www.instagram.com/napaz1ka/",
  },
  {
    name: "Amarello",
    org: "GaulesTV",
    twitter: "https://twitter.com/amarellogod",
    twitch: "https://www.twitch.tv/amarellogod",
    instagram: "https://www.instagram.com/amarellogod/",
  },
  {
    name: "B3ijin",
    org: "GaulesTV",
    twitter: "https://twitter.com/b3ijin",
    twitch: "https://www.twitch.tv/b3ijin_",
    instagram: "https://www.instagram.com/b3ijin/",
  },
  {
    name: "Fred bacon",
    org: "GaulesTV",
    twitter: "https://twitter.com/oFredBacon",
    twitch: "https://www.twitch.tv/ofredbacon",
    instagram: "https://www.instagram.com/ofredbacon/",
    avatar: "fred-bacon",
  },
  {
    name: "Wolfz",
    org: "Caster",
    twitter: "https://twitter.com/uWolfz",
    twitch: "https://www.twitch.tv/uwolfz",
    instagram: "https://www.instagram.com/uwolfz/",
  },
  {
    name: "Danyel",
    org: "Game Arena",
    twitter: "https://twitter.com/danyelmcarvalho",
    twitch: "https://www.twitch.tv/danyeLfps",
    instagram: "https://www.instagram.com/danyelmcarvalho/",
  },
  {
    name: 'Matheus Italo "TC_Spix"',
    org: "The Clutch",
    twitter: "https://twitter.com/TCF_Spix",
    twitch: "",
    instagram: "",
    avatar: "tc_spix",
  },
];
