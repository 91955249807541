import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";

import Judges from "../components/Judges";
import SponsorsArea from "../components/SponsorsArea";
import ChoicesReview from "../components/ChoicesReview";
import GameSwitch from "../components/GameSwitch";
import { Footer } from "../components/Footer";

import { indicated as indicatedValorant } from "../constants/valorant/indicated";
import {
  VALORANT_TOTAL_CATEGORIES,
  categories as categoriesValorant,
} from "../constants/valorant/categories";
import { GAME_TAG } from "../constants/game";
import { GC_AWARDS_API } from "../constants/api";

import {
  selectGame,
  confirmedVotesForValorant,
  voteForValorantInitiator,
  voteForValorantController,
  voteForValorantDuelist,
  voteForValorantSentinel,
  voteForValorantCaptain,
  voteForValorantCoach,
  voteForValorantOrg,
  voteForValorantFemalePlayer,
  voteForValorantMalePlayer,
  voteForValorantInfluencer,
  setErrorMessage,
} from "../actions/index";

import "../css/valorant.css";
import Voting from "../components/Voting";
import PremiereEvent from "../components/PremiereEvent";
import Winners from "../components/Winners";

const mapStateToProps = (state) => {
  return {
    hasVotedForValorant: state.app.hasVotedForValorant,
    initiator: state.valorant.initiator,
    controller: state.valorant.controller,
    duelist: state.valorant.duelist,
    sentinel: state.valorant.sentinel,
    captain: state.valorant.captain,
    coach: state.valorant.coach,
    organization: state.valorant.organization,
    femalePlayer: state.valorant.femalePlayer,
    malePlayer: state.valorant.malePlayer,
    influencer: state.valorant.influencer,
    expired: state.app.expired,
    finished: state.app.finished,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectGame: (game) => dispatch(selectGame(game)),
    confirmedVotesForValorant: (hasVotedForValorant) =>
      dispatch(confirmedVotesForValorant(hasVotedForValorant)),
    voteForValorantInitiator: (initiator) =>
      dispatch(voteForValorantInitiator(initiator)),
    voteForValorantController: (controller) =>
      dispatch(voteForValorantController(controller)),
    voteForValorantDuelist: (duelist) =>
      dispatch(voteForValorantDuelist(duelist)),
    voteForValorantSentinel: (sentinel) =>
      dispatch(voteForValorantSentinel(sentinel)),
    voteForValorantCaptain: (captain) =>
      dispatch(voteForValorantCaptain(captain)),
    voteForValorantCoach: (coach) => dispatch(voteForValorantCoach(coach)),
    voteForValorantOrg: (organization) =>
      dispatch(voteForValorantOrg(organization)),
    voteForValorantFemalePlayer: (femalePlayer) =>
      dispatch(voteForValorantFemalePlayer(femalePlayer)),
    voteForValorantMalePlayer: (malePlayer) =>
      dispatch(voteForValorantMalePlayer(malePlayer)),
    voteForValorantInfluencer: (influencer) =>
      dispatch(voteForValorantInfluencer(influencer)),
    setErrorMessage: (errorMessage) => dispatch(setErrorMessage(errorMessage)),
  };
}

class ConnectedValorant extends Component {
  componentDidMount() {
    this.props.selectGame("Valorant");
    this.props.setErrorMessage("");

    const cookies = new Cookies();

    if (cookies.get("gcmg:refreshToken")) {
      const authToken = cookies.get("gcmg:refreshToken");
      fetch(`${GC_AWARDS_API}/valorant`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.userAuthenticated) {
            this.props.setErrorMessage(
              "Falha ao validar sessão. Por favor, certifique-se de que você está logado."
            );
            ReactGA.event({
              category: "Action",
              action: "View",
              label: "Usuário acessou a página sem estar logado.",
            });
          } else {
            if (data.userHasVoted) {
              this.props.confirmedVotesForValorant(true);
              this.props.voteForValorantInitiator(data.userVotes.initiator);
              this.props.voteForValorantController(data.userVotes.controller);
              this.props.voteForValorantDuelist(data.userVotes.duelist);
              this.props.voteForValorantSentinel(data.userVotes.sentinel);
              this.props.voteForValorantCaptain(data.userVotes.captain);
              this.props.voteForValorantCoach(data.userVotes.coach);
              this.props.voteForValorantOrg(data.userVotes.organization);
              this.props.voteForValorantFemalePlayer(
                data.userVotes.femalePlayer
              );
              this.props.voteForValorantMalePlayer(data.userVotes.malePlayer);
              this.props.voteForValorantInfluencer(data.userVotes.influencer);
            } else {
              this.props.confirmedVotesForValorant(false);
            }
          }
        });
    } else {
      this.props.setErrorMessage(
        "Falha ao validar sessão. Por favor, certifique-se de que você está logado."
      );
    }
  }

  render() {
    let {
      initiator,
      controller,
      duelist,
      sentinel,
      captain,
      coach,
      organization,
      femalePlayer,
      malePlayer,
      influencer,
      hasVotedForValorant,
    } = this.props;

    let picks = [
      initiator,
      controller,
      duelist,
      sentinel,
      captain,
      coach,
      organization,
      femalePlayer,
      malePlayer,
      influencer,
      "",
    ];

    const categories = {
      ...Object.entries(categoriesValorant).reduce((accumlate, current) => {
        return {
          ...accumlate,
          [current[1]]: indicatedValorant.filter(
            (indicado) => indicado.categoria === current[0]
          ),
        };
      }, {}),
      Revisão: [{}],
    };

    let momentIndex = 0;

    let getIndex = () => {
      momentIndex = picks.findIndex((x) => x === "");
      let momentKey = Object.keys(categories)[momentIndex];
      let momentValues = Object.values(categories)[momentIndex];
      return { [momentKey]: momentValues };
    };

    let votingMoment = getIndex();

    return (
      <div>
        <GameSwitch game={GAME_TAG.VALORANT} />
        <SponsorsArea game={GAME_TAG.VALORANT} />
        {this.props.finished && <Winners game={GAME_TAG.VALORANT} />}
        <Judges game={GAME_TAG.VALORANT} />
        {!this.props.finished && (
          <PremiereEvent
            game={GAME_TAG.VALORANT}
            hasVoted={hasVotedForValorant}
          />
        )}
        {momentIndex === VALORANT_TOTAL_CATEGORIES &&
          !this.props.expired &&
          !this.props.finished && (
            <ChoicesReview
              votingMoment={votingMoment}
              steps={Object.keys(categories)}
              momentIndex={VALORANT_TOTAL_CATEGORIES}
              candidates={categories}
              game={GAME_TAG.VALORANT}
            />
          )}
        {momentIndex < VALORANT_TOTAL_CATEGORIES &&
          momentIndex > -1 &&
          !this.props.expired &&
          !this.props.finished && (
            <Voting
              votingMoment={votingMoment}
              steps={Object.keys(categories)}
              momentIndex={momentIndex}
              game={GAME_TAG.VALORANT}
            />
          )}
        <Footer game={GAME_TAG.VALORANT} />
      </div>
    );
  }
}

const Valorant = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedValorant);

export default Valorant;
