export const judges = [
  {
    name: 'Matheus "bzkA" Tarasconi',
    org: "MIBR",
    twitter: "https://twitter.com/bzkAgaming",
    twitch: "http://twitch.tv/bzkA",
    instagram: "http://instagram.com/bzkAgaming",
    avatar: "bzka",
  },
  {
    name: 'Jairo "Foxer"',
    org: "Game Arena",
    twitter: "https://twitter.com/Foxer_JJ",
    twitch: "https://www.twitch.tv/jairofoxer",
    instagram: "http://instagram.com/foxer_jj",
    avatar: "foxer",
  },
  {
    name: "spacca",
    org: "Riot Games",
    twitter: "https://twitter.com/guispacca",
    twitch: "http://twitch.tv/oldmanspacca ",
    instagram: "http://instagram.com/guispacca",
  },
  {
    name: "AMD",
    org: "Caster / Influencer",
    twitter: "https://twitter.com/AMD22k",
    twitch: "https://www.twitch.tv/amd22k",
    instagram: "https://www.instagram.com/amd22k",
  },
  {
    name: "xrm",
    org: "Riot Games",
    twitter: "https://twitter.com/xrmfps",
    twitch: "https://www.twitch.tv/xrmfps",
    instagram: "https://www.instagram.com/pabloxrm/",
  },
  {
    name: "Naoshii",
    org: "Analista / Comentarista",
    twitter: "https://twitter.com/naoshii_",
    twitch: "https://www.twitch.tv/naoshiitv",
    instagram: "https://www.instagram.com/naoshii_/",
  },
  {
    name: "Wesley Pereira",
    org: "The Clutch",
    twitter: "https://twitter.com/pereirawesley_",
    twitch: "",
    instagram: "",
    avatar: "wesley-pereira",
  },
  {
    name: "Anyazita",
    org: "Caster",
    twitter: "https://twitter.com/Anyazita_",
    twitch: "https://www.twitch.tv/anyazita",
    instagram: "https://www.instagram.com/anyazita_/",
  },
  {
    name: "Palestra",
    org: "Liquid",
    twitter: "https://twitter.com/palestrafps",
    twitch: "https://www.twitch.tv/palestrafps",
    instagram: "https://www.instagram.com/palestrafps/",
  },
  {
    name: 'Cristino "caco" Melo',
    org: "Valorant Zone",
    twitter: "https://twitter.com/cacmelo",
    twitch: "https://www.twitch.tv/cacmelo",
    instagram: "https://www.instagram.com/cacmelo",
    avatar: "caco",
  },
  {
    name: "Bebesita",
    org: "Kru",
    twitter: "https://twitter.com/beahornes",
    twitch: "",
    instagram: "https://www.instagram.com/beahornesc/",
  },
  {
    name: "Kakarota",
    org: "Caster",
    twitter: "https://twitter.com/aKakarota",
    twitch: "https://www.twitch.tv/akakarota",
    instagram: "https://www.instagram.com/akakarota/",
  },
  {
    name: "Chun",
    org: "Caster",
    twitter: "https://twitter.com/wildchun",
    twitch: "https://www.twitch.tv/wildchun",
    instagram: "https://www.instagram.com/wildchun/",
  },
  {
    name: "Kzuki",
    org: "Riot Games",
    twitter: "https://twitter.com/kzuki",
    twitch: "https://www.twitch.tv/kzuki",
    instagram: "https://www.instagram.com/kzuki__",
  },
  {
    name: "Canedo",
    org: "Riot Games",
    twitter: "https://twitter.com/andrecanedo_",
    twitch: "https://www.twitch.tv/andrecanedo",
    instagram: "https://www.instagram.com/andrecanedo_",
  },
  {
    name: "Risk1nner",
    org: "Caster",
    twitter: "https://twitter.com/risk1nner",
    twitch: "https://www.twitch.tv/risk1nner",
    instagram: "https://www.instagram.com/risk1nner/",
  },
  {
    name: "Gabxbi",
    org: "Caster",
    twitter: "https://twitter.com/gabxbii",
    twitch: "https://www.twitch.tv/gabxbi",
    instagram: "https://www.instagram.com/gabxbii/",
  },
  {
    name: "Fly",
    org: "GC / MIBR",
    twitter: "https://twitter.com/yurifly",
    twitch: "https://www.twitch.tv/gamersclub_fly",
    instagram: "https://www.instagram.com/yuri_fly/",
  },
  {
    name: "Bruno Povoleri",
    org: "The Enemy",
    twitter: "",
    twitch: "",
    instagram: "",
    avatar: "bruno-povoleri",
  },
  {
    name: "Paula Nobre",
    org: "FURIA",
    twitter: "https://twitter.com/paulanobrez",
    twitch: "https://www.twitch.tv/paulanobre",
    instagram: "https://www.instagram.com/paulanobrez/",
    avatar: "paula-nobre",
  },
];
