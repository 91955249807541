import React, { Component } from "react";
import { connect } from "react-redux";
import {
  voteForCsgoAssault,
  voteForCsgoAwp,
  voteForCsgoCaptain,
  voteForCsgoCoach,
  voteForCsgoOrg,
  voteForCsgoForeignPlayer,
  voteForCsgoMalePlayer,
  voteForCsgoFemalePlayer,
  voteForCsgoNewPlayer,
  voteForCsgoInfluencer,
  voteForCsgoLeaguePlayer,
  voteForCsgoLeagueOrg,
  voteForCsgoYoungPlayer,
  voteForValorantInitiator,
  voteForValorantController,
  voteForValorantDuelist,
  voteForValorantSentinel,
  voteForValorantCaptain,
  voteForValorantCoach,
  voteForValorantOrg,
  voteForValorantFemalePlayer,
  voteForValorantMalePlayer,
  voteForValorantInfluencer,
  choose,
} from "../actions/index";
import { importAllAssets } from "../utils/importAssets";
import { categories as categoriesCSGO } from "../constants/csgo/categories";
import { categories as categoriesValorant } from "../constants/valorant/categories";

import "../css/app.css";

const mapStateToProps = (state) => {
  return {
    hasVotedForValorant: state.app.hasVotedForValorant,
    hasVotedForCS: state.app.hasVotedForCS,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    voteForCsgoAssault: (assault) => dispatch(voteForCsgoAssault(assault)),
    voteForCsgoAwp: (awp) => dispatch(voteForCsgoAwp(awp)),
    voteForCsgoCaptain: (captain) => dispatch(voteForCsgoCaptain(captain)),
    voteForCsgoCoach: (coach) => dispatch(voteForCsgoCoach(coach)),
    voteForCsgoOrg: (org) => dispatch(voteForCsgoOrg(org)),
    voteForCsgoNewPlayer: (newPlayer) =>
      dispatch(voteForCsgoNewPlayer(newPlayer)),
    voteForCsgoForeignPlayer: (foreignPlayer) =>
      dispatch(voteForCsgoForeignPlayer(foreignPlayer)),
    voteForCsgoMalePlayer: (malePlayer) =>
      dispatch(voteForCsgoMalePlayer(malePlayer)),
    voteForCsgoFemalePlayer: (femalePlayer) =>
      dispatch(voteForCsgoFemalePlayer(femalePlayer)),
    voteForCsgoInfluencer: (influencer) =>
      dispatch(voteForCsgoInfluencer(influencer)),
    voteForCsgoLeaguePlayer: (leaguePlayer) =>
      dispatch(voteForCsgoLeaguePlayer(leaguePlayer)),
    voteForCsgoLeagueOrg: (leagueOrg) =>
      dispatch(voteForCsgoLeagueOrg(leagueOrg)),
    voteForCsgoYoungPlayer: (youngPlayer) =>
      dispatch(voteForCsgoYoungPlayer(youngPlayer)),
    voteForValorantInitiator: (initiator) =>
      dispatch(voteForValorantInitiator(initiator)),
    voteForValorantController: (controller) =>
      dispatch(voteForValorantController(controller)),
    voteForValorantDuelist: (duelist) =>
      dispatch(voteForValorantDuelist(duelist)),
    voteForValorantSentinel: (sentinel) =>
      dispatch(voteForValorantSentinel(sentinel)),
    voteForValorantCaptain: (captain) =>
      dispatch(voteForValorantCaptain(captain)),
    voteForValorantCoach: (coach) => dispatch(voteForValorantCoach(coach)),
    voteForValorantOrg: (organization) =>
      dispatch(voteForValorantOrg(organization)),
    voteForValorantFemalePlayer: (femalePlayer) =>
      dispatch(voteForValorantFemalePlayer(femalePlayer)),
    voteForValorantMalePlayer: (malePlayer) =>
      dispatch(voteForValorantMalePlayer(malePlayer)),
    voteForValorantInfluencer: (influencer) =>
      dispatch(voteForValorantInfluencer(influencer)),
    choose: (chosen) => dispatch(choose(chosen)),
  };
}

class ConnectedChosenCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "none",
    };
  }

  handleReview() {
    let { category, game } = this.props;

    if (!this.props.hasVotedForCS) {
      if (game === "CS") {
        switch (category) {
          case categoriesCSGO.MELHOR_ASSAULT:
            this.props.choose("");
            this.props.voteForCsgoAssault("");

            break;
          case categoriesCSGO.MELHOR_AWP:
            this.props.choose("");
            this.props.voteForCsgoAwp("");
            break;
          case categoriesCSGO.MELHOR_CAPITAO:
            this.props.choose("");
            this.props.voteForCsgoCaptain("");
            break;
          case categoriesCSGO.MELHOR_COACH:
            this.props.choose("");
            this.props.voteForCsgoCoach("");
            break;
          case categoriesCSGO.MELHOR_ORGANIZACAO:
            this.props.choose("");
            this.props.voteForCsgoOrg("");
            break;
          case categoriesCSGO.REVELACAO:
            this.props.choose("");
            this.props.voteForCsgoNewPlayer("");
            break;
          case categoriesCSGO.ESTRANGEIRO_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoForeignPlayer("");
            break;
          case categoriesCSGO.JOGADORA_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoFemalePlayer("");
            break;
          case categoriesCSGO.JOGADOR_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoMalePlayer("");
            break;
          case categoriesCSGO.PERSONALIDADE_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoInfluencer("");
            break;
          case categoriesCSGO.JOGADOR_LIGAS:
            this.props.choose("");
            this.props.voteForCsgoLeaguePlayer("");
            break;
          case categoriesCSGO.ORGANIZACAO_LIGAS:
            this.props.choose("");
            this.props.voteForCsgoLeagueOrg("");
            break;
          case categoriesCSGO.JOVEM_DO_ANO:
            this.props.choose("");
            this.props.voteForCsgoYoungPlayer("");
            break;
          default:
            return null;
        }
      }
    }

    if (!this.props.hasVotedForValorant) {
      if (game === "Valorant") {
        switch (category) {
          case categoriesValorant.MELHOR_INICIADOR:
            this.props.choose("");
            this.props.voteForValorantInitiator("");

            break;
          case categoriesValorant.MELHOR_CONTROLADOR:
            this.props.choose("");
            this.props.voteForValorantController("");
            break;
          case categoriesValorant.MELHOR_DUELISTA:
            this.props.choose("");
            this.props.voteForValorantDuelist("");
            break;
          case categoriesValorant.MELHOR_SENTINELA:
            this.props.choose("");
            this.props.voteForValorantSentinel("");
            break;
          case categoriesValorant.MELHOR_CAPITAO:
            this.props.choose("");
            this.props.voteForValorantCaptain("");
            break;
          case categoriesValorant.MELHOR_COACH:
            this.props.choose("");
            this.props.voteForValorantCoach("");
            break;
          case categoriesValorant.MELHOR_ORGANIZACAO:
            this.props.choose("");
            this.props.voteForValorantOrg("");
            break;
          case categoriesValorant.JOGADORA_DO_ANO:
            this.props.choose("");
            this.props.voteForValorantFemalePlayer("");
            break;
          case categoriesValorant.JOGADOR_DO_ANO:
            this.props.choose("");
            this.props.voteForValorantMalePlayer("");
            break;
          case categoriesValorant.PERSONALIDADE_DO_ANO:
            this.props.choose("");
            this.props.voteForValorantInfluencer("");
            break;
          default:
            return null;
        }
      }
    }
  }

  render() {
    let { name, game, avatar } = this.props;
    let avatarUrl = importAllAssets(
      require.context("../assets/avatar", false, /\.(png|jpe?g|svg)$/),
      name,
      avatar
    );

    if (game === "CS") {
      if (!this.props.hasVotedForCS) {
        return (
          <div
            onMouseOver={() => {
              this.setState({ display: "flex" });
              this.setState({ opacity: "0.7" });
            }}
            onMouseLeave={() => {
              this.setState({ display: "none" });
              this.setState({ opacity: "1" });
            }}
            className="chosen-review-card"
            onClick={() => this.handleReview()}
          >
            {this.state.display === "flex" ? (
              <div className={`review-this-card cs-review display-flex`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            ) : (
              <div className={`review-this-card cs-review display-none`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            )}
            <div className="category-review-name cs-category">
              <h1>{this.props.category}</h1>
            </div>
            <div className="judge-card-avt-area">
              <div className="judge-card-avt-outline">
                <div
                  className="chosen-card-avt"
                  style={{ backgroundImage: `url('${avatarUrl}')` }}
                ></div>
              </div>
            </div>
            <div className="judge-card-info">
              <h1>{this.props.name}</h1>
              <h2>{this.props.organization}</h2>
            </div>
          </div>
        );
      } else {
        return (
          <div className="chosen-review-card">
            {this.state.display === "flex" ? (
              <div className={`review-this-card cs-review display-flex`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            ) : (
              <div className={`review-this-card cs-review display-none`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            )}
            <div className="category-review-name cs-category">
              <h1>{this.props.category}</h1>
            </div>
            <div className="judge-card-avt-area">
              <div className="judge-card-avt-outline">
                <div
                  className="chosen-card-avt"
                  style={{ backgroundImage: `url('${avatarUrl}')` }}
                ></div>
              </div>
            </div>
            <div className="judge-card-info">
              <h1>{this.props.name}</h1>
              <h2>{this.props.organization}</h2>
            </div>
          </div>
        );
      }
    }

    if (game === "Valorant") {
      if (!this.props.hasVotedForValorant) {
        return (
          <div
            onMouseOver={() => {
              this.setState({ display: "flex" });
              this.setState({ opacity: "0.7" });
            }}
            onMouseLeave={() => {
              this.setState({ display: "none" });
              this.setState({ opacity: "1" });
            }}
            className="chosen-review-card"
            onClick={() => this.handleReview()}
          >
            {this.state.display === "flex" ? (
              <div className={`review-this-card valorant-review display-flex`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            ) : (
              <div className={`review-this-card valorant-review display-none`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            )}
            <div className="category-review-name valorant-category">
              <h1>{this.props.category}</h1>
            </div>
            <div className="judge-card-avt-area">
              <div className="judge-card-avt-outline">
                <div
                  className="chosen-card-avt"
                  style={{ backgroundImage: `url('${avatarUrl}')` }}
                ></div>
              </div>
            </div>
            <div className="judge-card-info">
              <h1>{this.props.name}</h1>
              <h2>{this.props.organization}</h2>
            </div>
          </div>
        );
      } else {
        return (
          <div className="chosen-review-card">
            {this.state.display === "flex" ? (
              <div className={`review-this-card valorant-review display-flex`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            ) : (
              <div className={`review-this-card valorant-review display-none`}>
                <svg
                  width="52"
                  height="48"
                  viewBox="0 0 52 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.1517 12.7499L38.5759 0.174072L35.9242 2.82572L43.9734 10.8749L3.50004 10.8749L3.50004 14.6249L43.9734 14.6249L35.9242 22.6741L38.5759 25.3257L51.1517 12.7499Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0.848389 35.2499L13.4242 22.6741L16.0759 25.3257L8.02669 33.3749H48.5V37.1249H8.02669L16.0759 45.1741L13.4242 47.8257L0.848389 35.2499Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Mudar</p>
              </div>
            )}
            <div className="category-review-name valorant-category">
              <h1>{this.props.category}</h1>
            </div>
            <div className="judge-card-avt-area">
              <div className="judge-card-avt-outline">
                <div
                  className="chosen-card-avt"
                  style={{ backgroundImage: `url('${avatarUrl}')` }}
                ></div>
              </div>
            </div>
            <div className="judge-card-info">
              <h1>{this.props.name}</h1>
              <h2>{this.props.organization}</h2>
            </div>
          </div>
        );
      }
    }
  }
}

const ChosenCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedChosenCard);

export default ChosenCard;
