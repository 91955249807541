// APP ACTION TYPES

export const CHOOSE = "CHOOSE";

export const SELECT_GAME = "SELECT_GAME";

export const HAS_VOTED_FOR_CS = "HAS_VOTED_FOR_CS";

export const HAS_VOTED_FOR_VALORANT = "HAS_VOTED_FOR_VALORANT";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

// CSGO ACTION TYPES

export const VOTE_CSGO_ASSAULT = "VOTE_CSGO_ASSAULT";

export const VOTE_CSGO_AWP = "VOTE_CSGO_AWP";

export const VOTE_CSGO_CAPTAIN = "VOTE_CSGO_CAPTAIN";

export const VOTE_CSGO_COACH = "VOTE_CSGO_COACH";

export const VOTE_CSGO_ORG = "VOTE_CSGO_ORG";

export const VOTE_CSGO_NEW_PLAYER = "VOTE_CSGO_NEW_PLAYER";

export const VOTE_CSGO_FOREIGN_PLAYER = "VOTE_CSGO_FOREIGN_PLAYER";

export const VOTE_CSGO_MALE_PLAYER = "VOTE_CSGO_MALE_PLAYER";

export const VOTE_CSGO_FEMALE_PLAYER = "VOTE_CSGO_FEMALE_PLAYER";

export const VOTE_CSGO_INFLUENCER = "VOTE_CSGO_INFLUENCER";

export const VOTE_CSGO_LEAGUE_PLAYER = "VOTE_CSGO_LEAGUE_PLAYER";

export const VOTE_CSGO_LEAGUE_ORG = "VOTE_CSGO_LEAGUE_ORG";

export const VOTE_CSGO_YOUNG_PLAYER = "VOTE_CSGO_YOUNG_PLAYER";

// VALORANT ACTION TYPES

export const VOTE_VALORANT_INITIATOR = "VOTE_VALORANT_INITIATOR";

export const VOTE_VALORANT_CONTROLLER = "VOTE_VALORANT_CONTROLLER";

export const VOTE_VALORANT_DUELIST = "VOTE_VALORANT_DUELIST";

export const VOTE_VALORANT_SENTINEL = "VOTE_VALORANT_SENTINEL";

export const VOTE_VALORANT_CAPTAIN = "VOTE_VALORANT_CAPTAIN";

export const VOTE_VALORANT_COACH = "VOTE_VALORANT_COACH";

export const VOTE_VALORANT_ORG = "VOTE_VALORANT_ORG";

export const VOTE_VALORANT_MALE_PLAYER = "VOTE_VALORANT_MALE_PLAYER";

export const VOTE_VALORANT_FEMALE_PLAYER = "VOTE_VALORANT_FEMALE_PLAYER";

export const VOTE_VALORANT_INFLUENCER = "VOTE_VALORANT_INFLUENCER";
