export const CSGO_TOTAL_CATEGORIES = 13;

export const categories = {
  MELHOR_ASSAULT: "Melhor Assault",
  MELHOR_AWP: "Melhor Awper",
  MELHOR_CAPITAO: "Melhor IGL",
  MELHOR_COACH: "Melhor Coach",
  MELHOR_ORGANIZACAO: "Melhor Organização",
  REVELACAO: "Revelação by Itaú",
  ESTRANGEIRO_DO_ANO: "Estrangeiro do Ano",
  JOGADORA_DO_ANO: "Player do ano (Cenário Inclusivo) by Itaú",
  JOGADOR_DO_ANO: "Player do ano (Cenário Misto) by Itaú",
  PERSONALIDADE_DO_ANO: "Personalidade do Ano by Itaú",
  JOGADOR_LIGAS: "Player da Liga (Ligas GC)",
  ORGANIZACAO_LIGAS: "Org Revelação (Ligas GC)",
  JOVEM_DO_ANO: 'Prêmio Matheus "brutt" Queiroz de Jovem do Ano by DRAFT5',
};
