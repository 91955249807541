import React, { Component } from "react";
import JudgeCard from "../components/JudgeCard";
// import ReactGA from "react-ga";

import { winners as winnersCsGo } from "../constants/csgo/winners"
import { winners as winnersValorant } from "../constants/valorant/winners"

class Winners extends Component {
  render() {
    let { game } = this.props;
    let winnersCards = [];
    let winners = [];

    if (game === "CS") {
      winners = winnersCsGo;
    }

    if (game === "Valorant") {
      winners = winnersValorant;
    }

    winners.forEach((winner) => {
      winnersCards.push(
        <>
          <JudgeCard
            name={winner.name}
            org={winner.org}
            twitch={winner.twitch}
            instagram={winner.instagram}
            twitter={winner.twitter}
            avatar={winner.avatar}
            category={winner.categoria}
            game={this.props.game}
            key={Math.random().toString(36).substring(2)}
          />
        </>
      );
    });

    return (
      <section className="pd-50">
        <div>
          <div className="section-title">
            <h1 className="judges-title">Parabéns aos vencedores!</h1>
          </div>
          <div>
            <hr
              className={`${
                game === "CS"
                  ? "cs"
                  : "valorant"
              }-hr`}
            />
          </div>
          <div className="container">{winnersCards}</div>
        </div>
      </section>
    );
  }
}

export default Winners;
